import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import province from "@/views/province";
import transfer from "@/views/transfer";
import linePriceTwoMap from "@/views/linePriceTwoMap";
import checkList from "@/views/checkList";
import commission from "@/views/commission";
import supplement from "@/views/supplement";
import transfer2 from "@/views/transfer2";
import commission2 from "@/views/commission2";
import priceManagement from "@/views/priceManagement";
import callCenter from "@/views/callCenter";
import checkbox from "@/views/checkbox";
import authRoute from "@/views/authRoute";
import chat from "@/views/chat";
import test from "@/views/test";
import offline from "@/views/offline";
import driverList from "../views/driverList";
import paidan from "../views/paidan";

Vue.use(VueRouter)

const routes = [
  { //划区
    path: '/',
    name: 'Home',
    component: Home
  },{ //划行政区
    path: '/province',
    name: 'province',
    component: province
  },{ //穿梭框
    path: '/transfer',
    name: 'transfer',
    component: transfer
  },{ //穿梭框2
    path: '/transfer2',
    name: 'transfer2',
    component: transfer2
  },{ //路线管理  双地图页面
    path: '/linePrice',
    name: 'linePriceTwoMap',
    component: linePriceTwoMap
  },{ //核单
    path: '/checkList',
    name: 'checkList',
    component: checkList
  },{ //抽佣
    path: '/commission',
    name: 'commission', 
    component: commission
  },{ //抽佣2
    path: '/commission2',
    name: 'commission2',
    component: commission2
  },{ //补单
    path: '/supplement',
    name: 'supplement',
    component: supplement
  },{ //价格管理
    path: '/priceManagement',
    name: 'priceManagement',
    component: priceManagement
  },{ //呼救中心
    path: '/callCenter',
    name: 'callCenter',
    component: callCenter
  },{ //多选
    path: '/checkbox',
    name: 'checkbox',
    component: checkbox
  },{ //授权路线
    path: '/authRoute',
    name: 'authRoute',
    component: authRoute
  },{
    path: '/test',
    name: 'test',
    component: test
  },{
    path: '/offline',
    name: 'offline',
    component: offline
  },{
    path: '/driverlist',
    name: 'driverList',
    component: driverList
  },{
    path: '/paidan',
    name: 'paidan',
    component: paidan
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
