<template>
  <div>
    <div style="width: 30vw;margin: 20px auto;">
      <div class="difb alc mrBtm20">
        <div class="dif alc">
          <div style="width: 150px;" class="dif alc">
            <el-input v-model="name" placeholder="请输入搜索内容"></el-input>
          </div>
          <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
        </div>
        <div class="dif alc">
          <el-button type="primary" icon="el-icon-arrow-left" @click="goBack()">返回</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </div>
      <el-checkbox-group v-model="checkList">
        <div v-for="(item,index) in list" :key="index" class="mrBtm10">
          <el-checkbox :label="item.id" >{{item.name}}</el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox",
  data() {
    return {
      id: '',
      checkList: [],
      list: [],
      name: '',
    }
  },
  methods: {
    search() {
      this.getList()
    },
    goBack() {
      window.location = 'https:/www.yanshixinxi.top/system/manage/index'
    },
    getChecked() { //回显
      this.$axios("POST","/index/MangerInfo",{id: this.id})
      .then(res => {
        if(res.status==1) {
          if(res.data.path_id) {
            let arr = res.data.path_id.split(',')
            arr = arr.map(item => {
              return item = Number(item)
            })
            this.checkList = arr
          }
        }
      })
    },
    save() { //保存
      let data = {
        ids: this.checkList.join(','),
        id: this.id
      }
      this.$axios("POST","/index/saveManger",data,'保存中')
      .then(res => {
        if(res.status==1) {
          this.$alert('保存成功',{type: 'success'})
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    getList() { //获取列表
      this.$axios("POST","/index/getPathLists",{place: this.name},'加载中')
      .then(res => {
        if(res.status==1) {
          this.list = res.data
        }else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id || '67'
    this.getChecked()
    this.getList()
  }
}
</script>

<style scoped>
  
</style>