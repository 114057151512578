<template>
  <div class="w100">
    <el-table :stripe="true" highlight-current-row ref="singleTable" :data="list">
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>
      <el-table-column align="center" prop="real_name" label="真实姓名" width="80"></el-table-column>
      <el-table-column align="center" prop="id_no" label="身份证号" width="120"></el-table-column>
      <el-table-column align="center" prop="type" label="车辆类型" width="100"></el-table-column>
      <el-table-column align="center" prop="carno" label="车牌号" width="100"></el-table-column>
      <el-table-column align="center" prop="color" label="颜色" width="150"></el-table-column>
      <el-table-column align="center"  label="图片" width="270">
        <template slot-scope="scope">
          <div class="dif difw" v-viewer.rebuild="{movable: true}">
            <img :src="item" v-for="(item,index) in scope.row.photoArr" class="bgccc pointer" style="display: block;margin-right: 10px;margin-bottom: 10px;width: 50px;height: 50px;">
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="state" label="状态" width="150"></el-table-column>
      <el-table-column align="center" prop="time" label="创建时间" width="200">
        <template slot-scope="scope">
          <div>{{$all.timestampToTime(scope.row.add_time)}}</div>
        </template>
      </el-table-column>
      <el-table-column  label="操作" >
        <template slot-scope="scope">
          <div class="">
            <div class="dif alc">
              <!--<el-button type="success" size="mini" @click="copy(scope.$index, scope.row)">复制</el-button>-->
              <!--<el-button type="success" size="mini" @click="sendCode(scope.$index, scope.row)">补发短信</el-button>-->
              <el-button type="warning" size="mini" @click="setUpdate(scope.$index, scope.row)">修改</el-button>
              <!--<el-button type="primary" size="mini" v-if="scope.row.status==1" @click="setDrive(scope.row)">指派</el-button>-->
              <!--<el-button type="danger" size="mini" v-if="scope.row.status==1 || scope.row.status==2" @click="cancelOrder(scope.row)">取消订单</el-button>-->
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>



    <el-dialog title="编辑" :visible.sync="showItem" width="40%" ref="form">
      <div style="max-height: 60vh;overflow-y: auto">
        <el-form :model="item" v-if="item" >
          <el-form-item label="真实姓名" label-width="100px">
            <el-input v-model="item.real_name" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item label="身份证号" label-width="100px">
            <el-input v-model="item.id_no" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="车牌号" label-width="100px">
            <el-input v-model="item.carno" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="颜色" label-width="100px">
            <el-input v-model="item.color" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="可乘坐人数" label-width="100px">
            <el-input v-model="item.num" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="车辆品牌" label-width="100px">
            <el-input v-model="item.car_id" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="车辆类型" label-width="100px">
            <el-select v-model="item.car_types"   placeholder="请选择车辆类型">
              <el-option
                  v-for="item in carType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌颜色" label-width="100px">
            <el-select v-model="item.carnocolor"   placeholder="请选择车牌颜色">
              <el-option
                  v-for="item in colors"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证正面" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'image_font')" accept="image/*">
              <img v-if="item.image_font" :src="$all.url+item.image_font" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证反" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'image_behind')" accept="image/*">
              <img v-if="item.image_behind" :src="$all.url+item.image_behind" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="驾驶证正面" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'driving_licence')" accept="image/*">
              <img v-if="item.driving_licence" :src="$all.url+item.driving_licence" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="驾驶证反面" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'driving_licence_behind')" accept="image/*">
              <img v-if="item.driving_licence_behind" :src="$all.url+item.driving_licence_behind" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="行驶证正面" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'driving_image')" accept="image/*">
              <img v-if="item.driving_image" :src="$all.url+item.driving_image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="行驶证反面" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'driving_image_behind')" accept="image/*">
              <img v-if="item.driving_image_behind" :src="$all.url+item.driving_image_behind" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="网络预约出租车驾驶员证" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'driving_web')" accept="image/*">
              <img v-if="item.driving_web" :src="$all.url+item.driving_web" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="网络预约出租车运输证" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'driver_apo')" accept="image/*">
              <img v-if="item.driver_apo" :src="$all.url+item.driver_apo" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="强制险" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'constraint_insurance')" accept="image/*">
              <img v-if="item.constraint_insurance" :src="$all.url+item.constraint_insurance" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="商业险" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'business_insurance')" accept="image/*">
              <img v-if="item.business_insurance" :src="$all.url+item.business_insurance" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="承运险" label-width="100px">
            <el-upload class="avatar-uploader" :show-file-list="false"
                       :action="$all.uploadUrl" :on-success="(res, file) =>upload(res, file,'carrier_insurance')" accept="image/*">
              <img v-if="item.carrier_insurance" :src="$all.url+item.carrier_insurance" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showItem=false">取 消</el-button>
        <el-button type="primary" @click="submit()">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "driverList",
  data() {
    return {
      id: '',
      list: [],
      showItem: false,
      item: {
        real_name: '',
        id_no: '',
        carno: '',
        color: '',
        num: '',
        image_font: '',
        image_behind: '',
        driving_licence: '',
        driving_licence_behind: '',
        driving_image: '',
        driving_image_behind: '',
        driving_web: '',
        driver_apo: '',
        id:'',
        car_id: '',
        car_types: '',
        carnocolor: '',
        constraint_insurance: '',
        business_insurance: '',
        carrier_insurance: '',
      },
      carType: [
        {id: 1,name: '汽油'},{id: 2,name: '柴油'},{id: 3,name: '电车'}
      ],
      colors: [
        {id: '1',name: '蓝色'},{id: '2',name: '绿色'}
      ]
    }
  },
  methods: {
    upload(res, file,key) {
      if(res.status==1) {
        this.item[key] = res.data.src
      }else {
        this.$message.error(res.msg)
      }
    },
    submit() {
      let data = JSON.parse(JSON.stringify(this.item))
      this.$axios('POST','/index/updateCertification',data,'保存中')
          .then(res => {
            if(res.status==1) {
              this.$message.success('保存成功')
              this.showItem = false
              this.getList()
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    setUpdate(index,item) {
      this.$axios('POST','/index/certificationDetail',{id: item.id},'加载中')
          .then(res => {
            if(res.status==1) {
              this.item = res.data
              for(let i in this.item) {
                this.item[i] = res.data[i] || ''
              }
              this.showItem = true
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    getList() {
      this.$axios('POST','/index/getDriverLists',{})
          .then(res => {
            if(res.status==1) {
              this.list = res.data.data
            }else {
              this.$message.error(res.msg)
            }
          })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px !important;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>