// let baseUrl = 'http://192.168.1.53:1083/api' //本地地址
let baseUrl = 'https:/www.yanshixinxi.top/api' //线上地址
function get(method,url,data,text) {
  let token = localStorage.getItem('caishui')?localStorage.getItem('caishui'):'';//获取token
  if(token) data.token = token
  return new Promise((resolve,reject)=>{
    let loading = ''
    if(text) {
      loading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
    //1创建异步对象
    const xhr = new XMLHttpRequest();
    //4创建监听 接收响应
    xhr.onreadystatechange=()=>{
      if(xhr.readyState==4 && xhr.status==200 || xhr.status==401){
        let result = JSON.parse(xhr.responseText);
        if(text) loading.close();
        resolve(result);//返回出去
      }else {
        if(text) loading.close();
      }
    };
    //2创建请求 打开连接
    xhr.open(method,url,true);
    xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded");
    xhr.setRequestHeader("token",token);
    let paramsData = ''
    if(method=='POST') {
      paramsData = '';//创建参数
      if(Object.keys(data) && Object.keys(data).length>0) {
        Object.keys(data).forEach(function(key) {//循环参数
          paramsData += key + '=' + encodeURIComponent(data[key]) + '&'
        })
        paramsData = paramsData.substring(0, paramsData.length - 1);//去除最后一位
      }
    }
    //3发送请求
    xhr.send(paramsData);
  })
}
export default get