<template>
  <div class="box" style="padding: 14px;">
    <!--地图-->
    <div class="difb alc" style="margin-top: 10px;">
      <!--左边起始地地图-->
      <div id="map1"></div>
      <!--右边结束地地图-->
      <div id="map2"></div>
    </div>
    <!--表头-->
    <div class="dif alc" style="margin-top: 30px;">
      <div class="dif alc">
        <div class="col333 fs18 mrRit20 bold">订单信息</div>
        <el-button type="primary"  @click="goBack()">返回</el-button>
      </div>
      <!--<div class="dif alc">
        <img src="@/assets/images/sijidangqian.png" style="width: 20px;height: auto">
        <p class="mrLft10 col3765FF">司机当前位置</p>
      </div>
      <div class="dif alc mrLft20">
        <img src="@/assets/images/paiban.png" style="width: 20px;height: auto">
        <p class="mrLft10 col3765FF">司机排班位置</p>
      </div>-->
      <!--<div class="dif alc mrLft20">-->
      <!--  <img src="@/assets/images/chengke.png" style="width: 20px;height: auto">-->
      <!--  <p class="mrLft10 col3765FF">乘客位置</p>-->
      <!--</div>-->
      <!--<div class="dif alc mrLft20">
        <img src="@/assets/images/baoguo.png" style="width: 20px;height: auto">
        <p class="mrLft10 col3765FF">包裹位置</p>
      </div>-->
    </div>
    <!--列表-->
    <div class="list mrTop30">
      <div class="list_item" v-for="(item,index) in list" :key="index">
        <span class="fs18 col333 bold mrRit10">{{item.id}}</span>
        <span class="fs14 mrRit10" style="color: #fa3534">出发时间：{{item.depart_time}}</span>

        <!--<span class="fs14 col333 mrRit10">[{{item.add_time}}]</span>-->
        <!--<span class="fs14 mrRit10" style="color: #3765FF">{{item.is_dai==0?'非本人':'本人'}}</span>-->
        <span class="fs14 mrRit10" style="color: #333333">{{item.place}} → {{item.destination}}</span>
        <span class="fs14 mrRit10" style="color: #333333">{{ item.num}}人</span>
        <span class="fs14 mrRit10" style="color: #3765FF">{{item.name}}</span>
        <span class="fs14 mrRit10" style="color: #3765FF">{{item.mobile}}</span>
        <span class="fs14 mrRit10" style="color: #333333">留言：{{item.remark || '无'}}</span>
        <span class="fs14 mrRit10" style="color: #3765FF" @click="setShowDrive(item)">查看司机信息</span>
        <el-button type="danger" size="mini" @click="designate(item.id)">撤销指派</el-button>
        <el-button type="warning" size="mini" @click="resetDrive(item.id)">重派司机</el-button>
        <el-button type="info" size="mini" @click="xiaodan(item.id)">消单</el-button>
        <!--<span class="pointer fs16 mrRit10" style="color: #fa3534;" @click="designate(item.id)">撤销指派</span>-->
        <!--<span class="pointer fs16 mrRit10" style="color: #fa3534;" @click="resetDrive(item.id)">重派司机</span>-->
        <!--<span class="pointer fs16 mrRit10" style="color: #fa3534;" @click="xiaodan(item.id)">消单</span>-->
      </div>
      <div v-if="list.length<=0" style="padding-top: 100px;">
        <el-result icon="info" title="信息提示" subTitle="暂无订单"></el-result>
      </div>
    </div>

    <!--取消指派司机-->
    <el-dialog
        title="重派司机" width="25%"
        :visible.sync="showDrive" top="30vh">
      <div>
        <div style="overflow-y: auto;height: 40vh;">
          <el-radio-group v-model="driveId">
            <div v-for="(item,index) in driveList" :key="index" class="mrBtm20">
              <el-radio :label="item.driverInfo.driver_id">{{item.driverInfo.real_name}}</el-radio>
            </div>
          </el-radio-group>
        </div>
        <div class="mrTop30 difcac">
          <el-button type="primary" @click="setDrive()">重派司机</el-button>
        </div>
      </div>
    </el-dialog>

    <!--查看司机信息-->
    <el-dialog
        title="司机信息" width="25%"
        :visible.sync="showDriveInfo" top="30vh">
      <div>
        <div>
          <p>司机姓名：{{dirveInfo.driver_real_name}}</p>
          <p style="margin: 10px 0;">司机电话：{{dirveInfo.driver_tel}}</p>
          <p>车牌号：{{dirveInfo.driver_car_no}}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "checkList",
  data() {
    return {
      id: '134',
      info: {
        place: '',//起始地
        destination: '',//结束地
      },
      map1: null, //第一个地图
      map2: null, //第二个地图
      pArr1: [],//第一个地图的划区
      pArr2: [],//第二个地图的划区
      priceList: [null,null], //选择的两个关联划区
      nameList: [null,null],

      setId: '',//设置id
      list: [],//核单列表
      driveList: [],//司机列表
      showDrive: false,//显示重派司机
      showPrice: false,//显示生成价格弹窗
      driveId: '',//当前选中的司机id

      railLogInfo: { //围栏绑定信息
        price: '',
        five_price: '',
        seven_price: '',
        parcel_price: '',
      },
      showDriveInfo: false,//显示司机信息
      dirveInfo: {
        "driver_tel":"",
        "driver_car_no":"",
        "driver_real_name":"",
      },
      defaultStrokeColor: '#006600', //默认未选中时线条颜色
      defaultFillColor: '#FFAA00', //默认未选中时填充颜色
      defaultStrokeActiveColor: '#000566', //默认选中时线条颜色
      defaultFillActiveColor: '#b69045', //默认选中时填充颜色

      priceStrokeColor: '#fa3534', //查看价格时未选中时线条颜色
      priceFillColor: '#409EFF', //查看价格时未选中时填充颜色
      priceStrokeActiveColor: '#1aff00', //查看价格时未选中时线条颜色
      priceFillActiveColor: '#224a6c', //查看价格时未选中时填充颜色

      strokeColor: '#006600', //未选中时线条颜色
      fillColor: '#FFAA00', //未选中时填充颜色
      strokeActiveColor: '#000566', //未选中时线条颜色
      fillActiveColor: '#b69045', //选中时填充颜色

    }
  },
  methods: {
    goBack() { //返回
      window.location = 'https:/www.yanshixinxi.top/system/bao/index'
    },
    //查看司机
    setShowDrive(item) {
      this.dirveInfo = {
        "driver_tel":item.driver_tel,
        "driver_car_no":item.driver_car_no,
        "driver_real_name":item.driver_real_name,
      }
      this.showDriveInfo = true
    },
    //获取司机列表
    getDriceList() {
      this.$axios("POST","/index/getDriverList",{})
      .then(res => {
        if(res.status==1) {
          this.driveList = res.data
        }
      })
    },
    //获取核单列表
    getRailLogInfo() {
      this.$axios("POST","/index/baoGetOrderLists",{id: this.id},'加载中').then(res => {
        if(res.status==1) {
          this.list = res.data
          for(let item of this.list) {
            this.addMarker2({
              name_rail: [item.lng,item.lat],
              name: item.id+ ',' +item.place
            },1)
            this.addMarker2({
              name_rail: [item.dao_lng,item.dao_lat],
              name: item.id+ ',' +item.destination
            },2)
          }
        }else {
          this.list = []
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 初始化地图
     * */
    init() {
      let that = this
      this.map1 = new AMap.Map("map1", {
        center: [117.078681,36.673923],//中心点
        zoom: 8,//缩放等级
      });
      this.map1.setFitView(); //自动将地图缩放到合适级别

      this.map2 = new AMap.Map("map2", {
        center: [117.078681,36.673923],//中心点
        zoom: 8,//缩放等级
      });
      this.map2.setFitView(); //自动将地图缩放到合适级别
      // this.polyEditor = new AMap.PolygonEditor(this.map); //创建地图Polygon编辑器
    },
    /**
     * 添加划区
     * @param type 1始发地 2目的地
     * */
    addPolygon(data,type) {
      let that = this;
      let polygons = []
      for(let item of data) {
        let polygon = new AMap.Polygon({
          path: JSON.parse(item.rail),
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
          fillOpacity: 0.5,
          strokeOpacity: 0.5
        })
        polygon.setExtData({
          id: item.id,
          pid: item.pid,
          name: item.name,
          name_rail: item.name_rail,
          mapIndex: type
        })
        that.addMarker2(item,type);//添加点标记
        //绑定双击事件
        polygon.on('click', (dbevent) => {
          that.setMapShowPrice(dbevent) //选择划区
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
        polygons.push(polygon)
      }
      //添加区域
      if(type==1) { //始发地
        this.map1.add(polygons)
        this.pArr1 = polygons
      }else { //结束地
        this.map2.add(polygons)
        this.pArr2 = polygons
      }
    },
    /**
     * 获取围栏列时添加点标记
     * */
    addMarker2(data,type) {
      if(data.name_rail) {
        let marker = new AMap.Marker({
          position: data.name_rail,
          // offset: new AMap.Pixel(-13, -30)
        });
        if(type==1) {
          marker.setMap(this.map1); //向地图添加标记
        }else {
          marker.setMap(this.map2); //向地图添加标记
        }
        // 自定义点标记内容
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = data.name;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
      }
      this.map1.setFitView(); //自动将地图缩放到合适级别
      this.map2.setFitView(); //自动将地图缩放到合适级别
    },
    /**
     * 消单
     * */
    xiaodan(id) {
      this.$confirm('确认要进行消单吗？',{type: 'warning'})
      .then(con => {
        this.$axios("POST","/index/delOrder",{id: id},'加载中')
        .then(res => {
          if(res.status==1) {
            this.$message.success('操作成功')
            setTimeout(() => {
              this.getRailLogInfo()
            },1200)
          }else {
            this.$alert(res.msg,{type: 'error'})
          }
        })
      })
    },
    /**
     * 重派司机
     * */
    resetDrive(id) {
      this.setId = id
      this.showDrive = true
    },
    setDrive() {
      if(!this.driveId) {
        return this.$alert('请选择司机',{type: 'error'})
      }
      this.$axios("POST","/index/designateDriver",{
        id: this.setId,
        driver_id: this.driveId
      },'加载中').then(res => {
        if(res.status==1) {
          this.showDrive = false
          this.$message.success('操作成功')
          setTimeout(() => {
            this.getRailLogInfo()
          },1200)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    /**
     * 取消指派
     * */
    designate(id) {
      this.$confirm('确认要取消指派？',{type: 'warning'})
          .then(con => {
            this.$axios("POST","/index/designate",{id: id},'加载中')
                .then(res => {
                  if(res.status==1) {
                    this.$message.success('操作成功')
                    setTimeout(() => {
                      this.getRailLogInfo()
                    },1200)
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })
    },

    /*
    * 以下没有用到，直接复制的 备用
    * */

    /**
     * 设置价格关联的区域样式
     * */
    setMapShowPrice(e) {
      let target = e.target //点击的地图对象
      let mapIndex = e.target.getExtData().mapIndex //目标所处的地图
      let arr = []
      let targetId = e.target.getExtData().id //点击的地图id
      if(mapIndex==2) { //第二个地图
        arr = this.pArr2
        this.priceList[1] = targetId
        this.nameList[1] = e.target.getExtData().name
      }else { //第一个地图
        arr = this.pArr1
        this.priceList[0] = targetId
        this.nameList[0] = e.target.getExtData().name
      }



      //设置鼠标移入 移除时的颜色
      this.strokeColor = this.priceStrokeColor
      this.fillColor = this.priceStrokeColor
      this.strokeActiveColor = this.priceStrokeActiveColor
      this.fillActiveColor = this.priceFillActiveColor
      //还原其他选取的颜色
      for(let item of arr) {
        let id = item._opts.extData.id
        if(id!=targetId) {
          item._opts.extData.isGuanlian = false //设置关联用字段
          item.setOptions({
            strokeColor: this.defaultStrokeColor,//线条颜色
            fillColor: this.defaultFillColor,//填充颜色
          })
        }
      }
      target._opts.extData.isGuanlian = true //设置关联用字段
      target.setOptions({
        strokeColor: this.strokeColor,//线条颜色
        fillColor: this.fillColor,//填充颜色
      })


      return

    },
    /**
     * 保存划区链接价格
     * */
    savePrice() {
      let data = this.railLogInfo
      data.zid = this.priceList[0]
      data.fid = this.priceList[1]
      if(!data.price || data.price<=0) { //拼车价格
        return this.$alert('请输入正确的拼车价格',{type: 'error'})
      }else if(!data.five_price || data.five_price<=0) { //五座价格
        return this.$alert('请输入正确的5座价格',{type: 'error'})
      }else if(!data.seven_price || data.seven_price<=0) { //七座
        return this.$alert('请输入正确的7座价格',{type: 'error'})
      }else if(!data.parcel_price || data.parcel_price<=0) { //包裹
        return this.$alert('请输入正确的包裹价格',{type: 'error'})
      }
      this.$axios("POST","/index/bindingRail",data,'保存中')
          .then(res => {
            if(res.status==1) {
              this.showPrice = false
              this.$alert('保存成功',{type: 'success'})
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    /**
     * 删除价格
     * */
    delPrice() {
      this.$confirm('确认要删除该价格吗？',{type: 'warning'})
          .then(con => {
            this.$axios("POST","/index/delRailLog",{
              zid: this.priceList[0],
              fid: this.priceList[1],
            },'删除中')
                .then(res => {
                  if(res.status==1) {
                    this.showPrice = false
                    this.$alert('删除成功',{type: 'success'})
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })
    },
    /**
     * 在创建划区的中心点创建text文本
     * */
    createText(position) {
      var text = new AMap.Text({
        text:'这是文本标记',
        anchor:'center', // 设置文本标记锚点
        draggable:false,
        cursor:'pointer',
        angle:10,
        style:{
          'padding': '5px',
          'border-radius': '.25rem',
          'background-color': '#FFFFFF',
          'border-width': 0,
          'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
          'text-align': 'center',
          'font-size': '12px',
          'color': 'blue'
        },
        position: position,
        extData: {index: this.pIndex}
      });
      text.setMap(this.map);
      this.tArr.push(text);
    },
    /**
     * 点击新建
     * */
    createPolygon() {
      this.resetPrice();
      this.polyName = ''
      this.polyEditor.close();
      this.polyEditor.setTarget();
      this.polyEditor.open();
      this.showSaveButton = true

      this.editorType = 1
    },
    /**
     * 保存修改/添加围栏/修改围栏
     * */
    save() {
      this.resetPrice();
      let options = this.currentTarget.getOptions();
      let data = {
        pid: this.id,
        rail: JSON.stringify(options.path),
        name: options.extData.name || '',
        name_rail: options.extData.name_rail || '',
      }
      if(this.polyName) {
        data.name = this.polyName || ''
        data.name_rail = JSON.stringify(this.currentNamePostion) || ''
      }
      if(options.extData.id) data.id = options.extData.id  //如果有id  修改选取
      let index = options.extData.index
      this.$message.info('保存中...')
      this.$axios("POST","/index/createRail",data)
          .then(res => {
            if(res.status==1) {
              this.$message.success('保存成功')
              //设置选区的附加属性
              let extData = this.pArr[index].getExtData();
              extData.id = res.data.id;
              this.pArr[index].setExtData(extData);
              this.addMarker(index,data); //设置标点
              //往表格中添加  划区数据
              if(res.data.name) {
                let index = this.tableData.findIndex(item => {
                  return item.id==res.data.id
                })
                console.log(index);
                if(index>=0) {
                  this.$set(this.tableData,index,res.data)

                  // this.tableData[index] = res.data;
                  // this.$forceUpdate();
                }else {
                  this.tableData.push(res.data)
                }
              }
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
      this.polyEditor.close(); //关闭编辑
      this.showEditor = false;
    },
    /**
     * 取消编辑
     * */
    cancel() { //取消
      if(this.editorType==1) { //删除新建的划区
        this.currentTarget.setMap(null); //删除划区
        this.currentTarget.destroy(); //销毁内存
        this.pArr.splice(this.pIndex,1);
        this.pIndex--
        this.polyEditor.close(); //关闭编辑
        this.showEditor = false;
        this.showSaveButton = false;
        this.editorType = 0
      }else { //还原编辑之前的选区
        let id = this.currentTarget.getExtData().id;
        let index = this.currentTarget.getExtData().index;
        this.resetPolygon(id,index)
      }
    },
    /**
     * 打开链接 划区  绑定事件
     * */
    openLink() {
      console.log(this.pArr.length);
      if(this.pArr.length<=1) {
        this.$alert('当前只有一个划区，请添加后重试',{type: 'error'})
      }
      this.showEditor = false
      /*let group = new AMap.OverlayGroup([polyEditor])
      let arr = group._overlays[0]*/
      let id = this.currentTarget.getExtData().id;
      this.linkArr.push(id);
      for(let item of this.pArr) {
        item.on('click',this.selectLink)
      }
    },
    /**
     * 清除绑定的链接事件
     *  */
    clearLink() {
      for(let item of this.pArr) {
        item.off('click',this.selectLink)
      }
      this.linkArr = []
    },
    /**
     * 选择并划区并绑定
     * */
    selectLink(item) {
      item = item.target
      let extData = item.getExtData(); //当前点击的划区参数
      if(extData.id==this.linkArr[0]) {
        return this.$alert('不可选择当前选中的区域',{type: 'error'})
      }else {
        this.linkArr.push(extData.id)
        this.showPrice = true
      }
    },
    /**
     * 编辑当前选中的划区
     * */
    openEditor() {
      this.showEditor = false
      this.polyEditor.setTarget(this.currentTarget);//设置添加的划区为选中的对象
      this.polyEditor.open();//打开该划区的编辑
      this.showSaveButton = true
    },

    /**
     * 关闭编辑窗口
     * */
    closeEditorWindow(type) {
      if(type==1) {
        this.showEditor = false;
      }else if(type==2) {
        this.showPrice = false
      }
    },
    /**
     * 行政区查询
     * */
    drawBounds() {
      // 搜索所有省/直辖市信息
      if(this.keyword==='') {
        return this.$alert('请输入搜索内容',{type: 'error'})
      }
      this.districtSearch.search(this.keyword, (status, result)=> {
        if(status==='no_data') {
          return this.$message.error('未搜索到结果')
        }
        if(status==='error') {
          return this.$message.error('搜索失败，请重试')
        }
        if(result.info === 'OK') {
          this.$message.success('添加成功')
          this.addSearchPolygon(result)
        }
      })
    },
    /**
     * 添加搜索行政区的划区
     * */
    addSearchPolygon(data) {
      let that = this;
      let polygons = []
      for(let path of data.districtList[0].boundaries) {
        let polygon = new AMap.Polygon({
          path: path,
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
        })
        polygon.setExtData({index: that.pIndex})
        that.pArr.push(polygon);
        that.polyEditor.addAdsorbPolygons(polygon); //添加划区
        // let center = that.getPointsCenter(polygon.getOptions().path); //获取中心点位置
        // that.createText(center);
        that.pIndex++ //测试用
        //绑定双击事件
        polygon.on('dblclick', (dbevent) => {
          that.currentNamePostion = [
            dbevent.lnglat.lng,
            dbevent.lnglat.lat,
          ]
          that.showEditor = true
          that.currentTarget = polygon;
          let name = that.currentTarget.getExtData().name || null;
          if(name) that.polyName = name
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
        polygons.push(polygon)
      }
      //添加区域
      this.map.add(polygons)
    },
    /**
     * 选择行政区查询等级
     * */
    changeLevel(e) {
      this.districtSearch.setLevel(e);
    },
    /**
     * 编辑时添加点标记
     * */
    addMarker(index,data) {
      if(this.tArr[index]) {
        console.log('修改');
        // 自定义点标记内容
        let marker = this.tArr[index]
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = this.polyName;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        marker.setPosition(JSON.parse(data.name_rail)); //更新点标记位置
        this.$forceUpdate()
      }else {
        let marker = new AMap.Marker({
          position: JSON.parse(data.name_rail)
        });
        marker.setMap(this.map); //向地图添加标记
        // 自定义点标记内容
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = this.polyName;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        this.tArr[index] = marker
        this.$forceUpdate()
      }
    },



    //表格操作
    selectRow(val) {
      this.currentRow = val;
    },
    /**
     * 表格点击查看按钮
     * */
    check(index,item) {
      let id = item.id
      console.log(this.pArr);
      let dbevent = this.pArr.find(item => {
        let dbId = item._opts.extData.id
        return id==dbId
      })
      if(dbevent) {
        this.currentNamePostion = JSON.parse(item.name_rail)
        this.showEditor = true
        this.currentTarget = dbevent;
        let name = this.currentTarget.getExtData().name || null;
        if(name) this.polyName = name
      }else {
        this.$alert('查看失败，请刷新重试',{type: 'error'})
      }

    },
    /**
     * 设置编辑区域价格
     * */
    setPrice(index,item) {
      this.updatePriceId = item.id
      this.polyPrice = item.price
      this.linkArr[0] = item.zid
      this.linkArr[1] = item.fid
      this.showPrice = true
    },
    /**
     * 取消区域价格显示
     * */
    resetPrice() {
      //恢复默认 鼠标移入 移除时的颜色
      this.showCheckPrice = false
      this.strokeColor = this.defaultStrokeColor
      this.fillColor = this.defaultFillColor
      this.strokeActiveColor = this.defaultStrokeActiveColor
      this.fillActiveColor = this.defaultFillActiveColor
      //恢复已有选区 的默认颜色
      for(let item of this.pArr) {
        item.setOptions({
          strokeColor: this.strokeColor,//线条颜色
          fillColor: this.fillColor,//填充颜色
        })
      }
    },


    //接口请求
    /**
     * 获取区域列表
     * */
    getList() {
      this.$axios("POST","/index/getRail",{id: this.id})
          .then(res => {
            if(res.status==1) {
              this.tableData = res.data.filter(item => {
                return item.name!==''
              })
              console.log(this.tableData);
              this.addPolygon(res.data)
              this.$message.success('获取成功')
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    /**
     * 删除选中的划区
     * */
    deletePolygon() {
      this.$confirm('确认要删除该围栏吗？',{type: 'warning'})
          .then(con=>{
            this.$message.info('删除中')
            let data = {
              ids: this.currentTarget.getExtData().id
            }
            this.$axios("POST","/index/delRail",data)
                .then(res => {
                  if(res.status==1) {
                    this.$message.success('删除成功')
                    this.polyName = ''
                    this.showEditor = false;
                    let index = this.currentTarget.getExtData().index;
                    try{
                      this.tArr[index].setMap(null);//删除划区中的文本内容
                    }catch (e) {}
                    this.currentTarget.setMap(null); //删除划区
                    this.currentTarget.destroy(); //销毁内存
                    this.clearLink(); //清除绑定的链接事件

                    //删除表格中的数据
                    let tindex = this.tableData.findIndex(item => {
                      return item.id == data.ids
                    })
                    if(tindex>=0) this.tableData.splice(tindex,1)
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })

    },
    /**
     * 取消已有选区编辑
     * */
    resetPolygon(id,index) {
      this.$axios("POST","/index/getRails",{
        id
      }).then(res => {
        if(res.status==1) {
          // let index = this.currentTarget.getExtData().index;
          let path = JSON.parse(res.data.rail)
          this.currentTarget.setPath(path)
          this.polyEditor.close(); //关闭编辑
          this.showEditor = false;
          this.showSaveButton = false;
          this.editorType = 0
        }
      })
    },

    /**
     * 获取围栏关联信息
     * */
    getRailLinkInfo(id=false) {
      if(!id) {
        id = this.currentTarget.getExtData().id;
      }
      this.priceId = id
      this.$message.info('获取中')
      this.$axios("POST","/index/getRailLinkInfo",{id: id})
          .then(res => {
            if(res.status==1) {
              this.showEditor = false
              this.priceList = res.data
              this.showCheckPrice = true
              this.setMapShowPrice() //设置价格关联的区域样式
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
  },
  mounted() {
    this.id = this.$route.query.id || '134'
    this.init(); //初始化地图
    this.getRailLogInfo() //获取核单列表
    this.getDriceList() //获取司机列表
  }
}
</script>

<style scoped>
#map1,#map2{
  width: 48%;
  height: 50vh;
  border: 1px solid #000;
}
.list{
  overflow-x: auto;
  width: 100%;
}
.list_item{
  min-height: 40px;
  background-color: rgb(232,246,252);
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 40px;
}
.list_item:nth-child(2n) {
  background-color: #ffffff;
}
</style>