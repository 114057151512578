<template>
  <div>
    <div style="width: 800px;margin: 30px auto;background-color: #EEEEEE" class="difb alc">
      <el-button type="primary" icon="el-icon-arrow-left" @click="goBack()">返回</el-button>
      <el-button type="primary"  @click="save()">保存</el-button>
    </div>
    <!--表单-->
    <div style="width: 800px;margin: 0 auto;">
      <div style="border-bottom: 1px solid #b5b5b5;padding-bottom: 10px;margin-bottom: 10px;" v-for="(item,index) in list" :key="index">
        <div class="mrBtm10">
          <div class="dif alc mrBtm10">
            <div class="w10">车型名称</div>
            <div class="w90 box pdLft30">
              <div><el-input disabled :value="item.name"></el-input></div>
            </div>
          </div>
          <!--夜间服务开启-->
          <div class="dif alc">
            <div class="dif alc mrRit30">
              <p style="margin-bottom: 5px;">夜间服务开启：</p>
              <el-radio v-model="item.night" :label="1">开</el-radio>
              <el-radio v-model="item.night" :label="0">关</el-radio>
            </div>
            <el-time-picker
                is-range v-if="item.night"
                v-model="item.nightTime" :clearable="false"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
            </el-time-picker>
          </div>
          <!--假期服务开启-->
          <div class="dif alc ">
            <p style="margin-bottom: 5px;">假期服务开启：</p>
            <el-radio v-model="item.vacation" :label="1">开</el-radio>
            <el-radio v-model="item.vacation" :label="0">关</el-radio>
          </div>
        </div>
        <!--时间价格-->
        <div class="dif alc " style="border-bottom: 1px dashed #eee;padding-bottom: 5px;margin-bottom: 5px;">
          <div class="w10  pdTop10">时间价格</div>
          <div class="w90 box pdLft30">
            <div class="dif alc fs14 col666 pdTop10">
              <p>平常价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.timePrice.normal"></el-input></div>
              <p class="mrLft10">元/分钟</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.night">
              <p>夜间价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.timePrice.night"></el-input></div>
              <p class="mrLft10">元/分钟</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.vacation">
              <p>假期价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.timePrice.vacation"></el-input></div>
              <p class="mrLft10">元/分钟</p>
            </div>
          </div>
        </div>
        <!--里程价格-->
        <div class="dif alc" style="border-bottom: 1px dashed #eee;padding-bottom: 5px;margin-bottom: 5px;">
          <div class="w10  pdTop10">里程价格</div>
          <div class="w90 box pdLft30">
            <div class="dif alc fs14 col666 pdTop10">
              <p>平常价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.mileagePrice.normal"></el-input></div>
              <p class="mrLft10">元/公里</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.night">
              <p>夜间价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.mileagePrice.night"></el-input></div>
              <p class="mrLft10">元/公里</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.vacation">
              <p>假期价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.mileagePrice.vacation"></el-input></div>
              <p class="mrLft10">元/公里</p>
            </div>
          </div>
        </div>
        <!--起步价格-->
        <div class="dif alc" style="border-bottom: 1px dashed #eee;padding-bottom: 5px;margin-bottom: 5px;">
          <div class="w10  pdTop10">起步价格</div>
          <div class="w90 box pdLft30">
            <div class="dif alc fs14 col666 pdTop10">
              <p>平常价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingPrice.normal"></el-input></div>
              <p class="mrLft10">元</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.night">
              <p>夜间价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingPrice.night"></el-input></div>
              <p class="mrLft10">元</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.vacation">
              <p>假期价格：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingPrice.vacation"></el-input></div>
              <p class="mrLft10">元</p>
            </div>
          </div>
        </div>
        <!--起步里程-->
        <div class="dif alc" style="border-bottom: 1px dashed #eee;padding-bottom: 5px;margin-bottom: 5px;">
          <div class="w10  pdTop10">起步里程</div>
          <div class="w90 box pdLft30">
            <div class="dif alc fs14 col666 pdTop10">
              <p>平常：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingMileage.normal"></el-input></div>
              <p class="mrLft10">公里</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.night">
              <p>夜间：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingMileage.night"></el-input></div>
              <p class="mrLft10">公里</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.vacation">
              <p>假期：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingMileage.vacation"></el-input></div>
              <p class="mrLft10">公里</p>
            </div>
          </div>
        </div>
        <!--起步时间-->
        <div class="dif alc" style="border-bottom: 1px dashed #eee;padding-bottom: 5px;margin-bottom: 5px;">
          <div class="w10  pdTop10">起步时间</div>
          <div class="w90 box pdLft30">
            <div class="dif alc fs14 col666 pdTop10">
              <p>平常：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingTime.normal"></el-input></div>
              <p class="mrLft10">分钟</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.night">
              <p>夜间：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingTime.night"></el-input></div>
              <p class="mrLft10">分钟</p>
            </div>
            <div class="dif alc fs14 col666 pdTop10" v-if="item.vacation">
              <p>假期：</p>
              <div><el-input placeholder="请输入" type="number" v-model="item.startingTime.vacation"></el-input></div>
              <p class="mrLft10">分钟</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "priceManagement",
  data() {
    return {
      id: '',
      showNight: false,//显示夜间价格
      testTime: [new Date(2022, 9, 10, 8, 0), new Date(2022, 9, 10, 23, 59)],
      showVacation: false,//显示假期价格
      list: [],
    }
  },
  methods: {
    //返回
    goBack() {
      window.location = 'https:/www.yanshixinxi.top/system/price/index'
    },
    //获取列表
    getList() {
      this.$axios("POST","/index/priceInfo",{
        id: this.id
      },'加载中')
      .then(res => {
        if(res.status==1) {
          if(res.data.list) {
            let list = JSON.parse(res.data.list)
            for(let item of list) {
              let obj = item
              obj.nightTime[0] = new Date(item.nightTime[0]*1000);
              obj.nightTime[1] = new Date(item.nightTime[1]*1000);
              this.list.push(obj)
            }
          }else {
            for(let item of res.data.carArr) {
              let obj = {
                id: item.id,
                name: item.title,//车辆名称
                night: 0,//夜间开启 0关闭 1开启
                vacation: 0,//假期开启 0关闭 1开启
                nightTime: [new Date(2022, 9, 10, 8, 0), new Date(2022, 9, 10, 23, 59)],//夜间时间
                timePrice: {
                  normal: '',night: '',vacation: ''
                },//时间价格
                mileagePrice: {
                  normal: '',night: '',vacation: ''
                },//里程价格
                startingPrice: {
                  normal: '',night: '',vacation: ''
                },//起步价格
                startingMileage: {
                  normal: '',night: '',vacation: ''
                },//起步价格
                startingTime: {
                  normal: '',night: '',vacation: ''
                }//起步时间
              }
              this.list.push(obj)
            }
          }
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //保存
    save() {
      let arr = []
      for(let item of this.list) {
        let obj = JSON.parse(JSON.stringify(item))
        obj.nightTime[0] = (item.nightTime[0].getTime() / 1000).toFixed(0)
        obj.nightTime[1] = (item.nightTime[1].getTime() / 1000).toFixed(0)
        arr.push(obj)
      }
      let data = {
        list: JSON.stringify(arr),
        id: this.id
      }
      this.$axios("POST","/index/price",data,'加载中')
      .then(res => {
        if(res.status==1) {
          this.$alert(res.msg,{type: "success"})
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id || '1'
    this.getList()
  }
}
</script>

<style scoped>

</style>