<template>
  <div>
    <el-dialog
         width="460px" :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="showPrice" top="20vh">
      <div slot="title">
        <el-button type="primary"  @click="goBack()">返回</el-button>
      </div>
      <div class="" style="overflow-y:auto;max-height: 50vh">
        <div class="difcac mrBtm10 alc">
          <div>路线名称：</div>
          <div>
            <el-input disabled v-model="name"></el-input>
          </div>
        </div>

        <div class="difcac mrBtm10 alc">
          <div>拼车价格：</div>
          <div>
            <el-input v-model="railLogInfo.pin_commission" placeholder="请输入拼车价格"></el-input>
          </div>
        </div>
        <!--<div class="difcac mrBtm10 alc" v-for="(item,index) in railLogInfo.carArr" :key="item.id">
          <div>{{item.title}}：</div>
          <div>
            <el-input v-model="item.price" :placeholder="'请输入'+item.title+'价格'"></el-input>
          </div>
        </div>-->
        <div class="difcac mrBtm10 alc">
          <div>包车价格：</div>
          <div>
            <el-input v-model="railLogInfo.all_commission" placeholder="请输入包车价格"></el-input>
          </div>
        </div>
        <div class="difcac mrBtm10 alc">
          <div>包裹价格：</div>
          <div>
            <el-input v-model="railLogInfo.bao_commission" placeholder="请输入包裹价格"></el-input>
          </div>
        </div>
        <div class="mrTop20 difcac alc">
          <el-button type="success" @click="savePrice()">保 存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "commission",
  data() {
    return {
      id: '',
      showPrice: false,//显示生成价格弹窗
      name: '',
      railLogInfo: { //围栏绑定信息
        pin_commission: '',
        bao_commission: '',
        // carArr: [],
        all_commission: '',
      },
    }
  },
  methods: {
    goBack() {
      window.location = 'https:/www.yanshixinxi.top/system/shop/index'
    },
    //保存
    savePrice() {
      // let str = JSON.stringify(this.railLogInfo.carArr)
      // console.log(str);
      let data = {
        id: this.id,
        pin_commission: this.railLogInfo.pin_commission,
        bao_commission: this.railLogInfo.bao_commission,
        all_commission: this.railLogInfo.all_commission
        // commission: str
      }
      this.$axios("POST","/index/pathCommission",data,'加载中')
      .then(res => {
        if(res.status==1) {
          this.$alert('保存成功',{type:'success'})
        }else {
          this.$alert(res.msg,{type:'error'})
        }
      })
      console.log(data);
    },
    //获取分佣详情
    getDetail() {
      this.$axios("POST","/index/pathInfo",{
        id: this.id
      },'加载中').then(res => {
        this.showPrice = true
        if(res.status==1) {
          this.name = res.data.name
          this.railLogInfo = res.data
        }else {
          this.$message.error(res.msg)
          this.railLogInfo = { //围栏绑定信息
            price: '',
            parcel_price: '',
            carArr: res.data
          }
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id || '1'
    this.getDetail(); //获取分佣详情
  }

}
</script>

<style scoped>

</style>