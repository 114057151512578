<template>
  <div class="box" style="padding: 10px;">
    <div class="dif alc">
      <h2 style="margin-right: 30px;">派单</h2>
      <el-select v-model="pathId" clearable @change="changePath" placeholder="请选择路线">
        <el-option
            v-for="item in paths"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-arrow-left" @click="back()">返回</el-button>
    </div>
    <el-table ref="table" :stripe="true" highlight-current-row  :data="list" @selection-change="handleSelectionChange">
      <el-table-column  type="selection" width="55"></el-table-column>
      <el-table-column align="center" prop="id" label="ID" width="150"></el-table-column>
      <el-table-column align="center" prop="time" label="用车时间" width="200">
        <template slot-scope="scope">
          <div>
            <span>{{scope.row.depart_time}} </span>
            <span class="text-red"> {{scope.row.depart_time_time}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="mobile" label="手机号" width="120"></el-table-column>
      <el-table-column align="center" prop="num" label="人数" width="80"></el-table-column>
      <el-table-column align="center" prop="ordertype" label="订单类型" width="100"></el-table-column>
      <el-table-column align="center" prop="place" label="出发地" width="200"></el-table-column>
      <el-table-column align="center" prop="destination" label="目的地" width="150"></el-table-column>
      <el-table-column  >
        <template slot="header" slot-scope="scope">
          <div class="dif alc">
            <p style="margin-right: 30px;">操作</p>
            <el-button type="success" size="mini" @click="pais()">一键派单</el-button>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="">
            <div class="dif alc">
              <el-button type="primary" size="mini" @click="paidan(scope.$index,scope.row)">派单</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "paidan",
  data() {
    return {
      id: '',
      bao_id: '',
      pathId: '',
      list: [],//订单列表
      paths: [],//路线列表
      selects: [],
    }
  },
  methods: {
    back() {
      window.location = 'https:/www.yanshixinxi.top/system/bao/index.html'
    },
    pais() {
      if(this.selects.length<=0) {
        return this.$alert('请选择要派单的订单',{type: 'warning'})
      }
      this.$axios('POST','/index/pai',{
        id: this.selects.map(item => item.id).join(','),
        bao_id: this.bao_id
      },'派单中').then(res => {
        if(res.status==1) {
          this.$message.success('派单成功')
          this.getList()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    cancel() {
      this.$refs.table.clearSelection();
      this.selects = []
    },
    paidan(index,item) { //派单
      this.$axios('POST','/index/pai',{
        id: item.id,
        bao_id: this.bao_id
      },'派单中').then(res => {
        if(res.status==1) {
          this.$message.success('派单成功')
          this.getList()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val);
      this.selects = val
    },
    getList() {
      this.cancel()
      let url = '/index/senGetOriginalOrder'
      let data = {id: this.id}
      if(this.pathId) {
        url = '/index/senGetOrderList'
        data.id = this.pathId
      }
      this.$axios('POST',url,data,'加载中')
          .then(res => {
            if(res.status==1) {
              this.list = res.data
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    changePath(e) {
      this.getList()
    },
    getPath() {
      this.$axios('POST','/index/getSupplierPath',{id: this.id})
          .then(res => {
            if(res.status==1) {
              this.paths = res.data
            }else {
              this.$message.error(res.msg)
            }
          })
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.bao_id = this.$route.query.bao_id
    this.getList()
    this.getPath()
  }
}
</script>

<style scoped>

</style>