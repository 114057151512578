<template>
  <div>
    <div class="input-card   btn-group dife alc">
      <div class="dif alc mrRit10">
        <!--<el-select v-model="districtLevel" placeholder="请选择" style="width: 100px;" @change="changeLevel">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>-->
        <el-button type="primary"  @click="goBack()">返回</el-button>
        <div style="width: 300px;" class="dif alc">
          <el-input v-model="keyword" placeholder="请输入搜索内容"></el-input>
        </div>
        <el-button type="primary" icon="el-icon-search" @click="drawBounds">搜索</el-button>
      </div>
      <el-button plain type="primary" @click="createPolygon()" v-if="!showSaveButton">新建区域</el-button>
      <el-button plain type="success" @click="save()" v-if="showSaveButton">保存区域</el-button>
      <el-button plain type="success" @click="cancel()" v-if="showSaveButton">取消</el-button>
    </div>
    <div class="difb">
      <div id="container"></div>
      <!--全部列表-->
      <div class="right" v-show="!showCheckPrice">
        <el-table
            highlight-current-row
            ref="singleTable"
            :data="tableData"
            @current-change="selectRow"
            style="width: 100%">
          <el-table-column
              prop="name"
              label="方位管理"
              width="240">
          </el-table-column>
          <el-table-column  width="150">
            <template slot-scope="scope" slot="header">
              <div class="dif alc">
                <el-button type="primary" size="mini" v-if="isAllCheck" @click="changeAllShow(false)">全部显示</el-button>
                <el-button type="danger" size="mini" v-else @click="changeAllShow(true)">全部隐藏</el-button>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="dif alc">
                <el-button type="success" size="mini" @click="check(scope.$index, scope.row)">查看</el-button>
                <el-button type="primary" size="mini" v-if="scope.row.isHide" @click="changeShow(scope.$index, scope.row)">显示</el-button>
                <el-button type="danger" size="mini" v-else @click="changeShow(scope.$index, scope.row)">隐藏</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--查看关联划区列表-->
      <div class="right" v-show="showCheckPrice">
        <el-table
            highlight-current-row
            ref="singleTable"
            :data="priceList"
            @current-change="selectRow"
            style="width: 100%">
          <!--名称-->
          <el-table-column
              label="方位价格"
              width="150">
            <template slot="header" slot-scope="scope">
              <el-button type="primary" icon="el-icon-arrow-left" @click="resetPrice()">返回</el-button>
            </template>
            <template slot-scope="scope">{{scope.row.railInfo.name}}</template>
          </el-table-column>
          <!--价格-->
          <el-table-column
              prop="price"
              label="价格"
              width="110">
          </el-table-column>
          <!--操作-->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="setPrice(scope.$index, scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--编辑弹窗-->
    <el-dialog
        title="编辑"
        :visible.sync="showEditor" top="30vh" width="50%">
      <div>
        <div style="width: 100%;">
          <el-input v-model="polyName" placeholder="请输入选区名称"></el-input>
        </div>
        <div class="difcac mrTop30">
          <el-button type="primary" @click="openEditor()">边界编辑</el-button>
          <!--<el-button type="primary" @click="openLink()">区域链接</el-button>-->
          <el-button type="primary" @click="deletePolygon()">删除选区</el-button>
          <!--<el-button type="primary" @click="getRailLinkInfo()">查看价格</el-button>-->
          <el-button type="primary" @click="save()">保存修改</el-button>
        </div>
      </div>
    </el-dialog>
    <!--链接划区价格弹窗-->
    <el-dialog
        title="链接" width="50%"
        :visible.sync="showPrice" top="30vh">
      <div>
        <div style="width: 100%;">
          <el-input v-model="polyPrice" placeholder="请输入价格"></el-input>
        </div>
        <div class="mrTop30 dife">
          <el-button type="primary" @click="savePrice()">保 存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      id: '',//地区id

      map: null,//map对象
      polyEditor: null,//划区编辑器对象
      districtSearch: null,//行政区查询
      districtLevel: 'district',//当前选中的搜索等级
      options: [
        {label: '区/县',value: 'district'},
        {label: '市',value: 'city'},
      ],

      keyword: '',//搜索行政区关键字
      polyName: '',//选区名称
      polyPrice: '',//选区价格


      defaultStrokeColor: '#006600', //默认未选中时线条颜色
      defaultFillColor: '#FFAA00', //默认未选中时填充颜色
      defaultStrokeActiveColor: '#000566', //默认选中时线条颜色
      defaultFillActiveColor: '#b69045', //默认选中时填充颜色

      priceStrokeColor: '#fa3534', //查看价格时未选中时线条颜色
      priceFillColor: '#409EFF', //查看价格时未选中时填充颜色
      priceStrokeActiveColor: '#1aff00', //查看价格时未选中时线条颜色
      priceFillActiveColor: '#224a6c', //查看价格时未选中时填充颜色

      strokeColor: '#006600', //未选中时线条颜色
      fillColor: '#FFAA00', //未选中时填充颜色
      strokeActiveColor: '#000566', //未选中时线条颜色
      fillActiveColor: '#b69045', //选中时填充颜色
      currentTarget: null, //当前选中的划区
      currentNamePostion: [0,0],//当前点击的经纬度

      pArr: [],//划区对象数组
      tArr: [],//标记文本对象数组
      linkArr: [],//选中的链接数组
      pIndex: 0,//测试用 每个划区的参数 index

      isAllCheck: false,//当前是否全选
      showEditor: false,//显示编辑弹窗
      showPrice: false,//显示连接划区价格弹窗
      showSaveButton: false,//显示保存按钮
      showCheckPrice: false,//显示查看区域价格
      priceList: [],//和选中区域相关的划区价格列表
      priceId: '',//选中查看价格的主区的id
      updatePriceId: '',//要修改的价格id

      editorType: 0,// 0 编辑已有选区 1 新建选区

      tableData: [],//划区数据
      currentRow: null,//当前选中的区域
    }
  },
  methods: {
    goBack() {
      window.location = 'https:/www.yanshixinxi.top/system/orientation/index'
    },
    /**
     * 初始化地图
     * */
    init() {
      let that = this
      this.map = new AMap.Map("container", {
        center: [117.078681,36.673923],//中心点
        zoom: 8,//缩放等级
      });
      //地图点击事件
      this.map.on('click', function (data) {
        that.currentNamePostion = [data.lnglat.getLng(),data.lnglat.getLat()]
      })
      this.polyEditor = new AMap.PolygonEditor(this.map); //创建地图Polygon编辑器
      /**
       *划区编辑器添加事件
       *  */
      this.polyEditor.on('add', function (data) {
        let polygon = data.target; //target即为创建对象
        //设置区域样式（结束编辑前有效）
        that.currentTarget = data.target;
        polygon.setOptions({
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
          strokeOpacity: 0.5,
          fillOpacity: 0.5
        })
        polygon.setExtData({index: that.pIndex})
        that.pArr.push(polygon);
        that.polyEditor.addAdsorbPolygons(polygon); //添加划区
        that.pIndex++ //测试用
        //绑定双击事件
        polygon.on('dblclick', (dbevent) => {
          that.currentNamePostion = [
              dbevent.lnglat.lng,
              dbevent.lnglat.lat,
          ]
          that.showEditor = true
          that.currentTarget = polygon;
          let name = that.currentTarget.getExtData().name || null;
          if(name) that.polyName = name
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
      })
      /**
       *划区编辑器添加结束事件
       *  */
      this.polyEditor.on('end', function (data) {
        let polygon = data.target; //target即为创建对象
        //设置区域样式(结束编辑后保持样式)
        polygon.setOptions({
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
          strokeOpacity: 0.5,
          fillOpacity: 0.5
        })
        that.showSaveButton = false
      })
      /**
       * 初始化行政区搜索
       * */
      this.districtSearch = new AMap.DistrictSearch({
        // 关键字对应的行政区级别，country表示国家
        level: 'district',
        //  显示下级行政区级数，1表示返回下一级行政区
        subdistrict: 0,
        showbiz: false,
        extensions: 'all'
      })
    },
    /**
     * 获取中心经纬度
     * */
    getPointsCenter(arr) {
      let points = [];
      for(let item of arr) {
        points.push(item.join(','))
      }
      let point_num = points.length; //坐标点个数
      let X = 0, Y = 0, Z = 0;
      for(let i = 0; i< points.length; i++) {
        if (points[i] == '') {
          continue;
        }
        let point = points[i].split(',');
        let lat, lng, x, y, z;
        lat = parseFloat(point[1]) * Math.PI / 180;
        lng = parseFloat(point[0]) * Math.PI / 180;
        x = Math.cos(lat) * Math.cos(lng);
        y = Math.cos(lat) * Math.sin(lng);
        z = Math.sin(lat);
        X += x;
        Y += y;
        Z += z;
      }
      X = X / point_num;
      Y = Y / point_num;
      Z = Z / point_num;

      let tmp_lng = Math.atan2(Y, X);
      let tmp_lat = Math.atan2(Z, Math.sqrt(X * X + Y * Y));

      return [ tmp_lng * 180 / Math.PI,tmp_lat * 180 / Math.PI];
    },
    /**
     * 在创建划区的中心点创建text文本
     * */
    createText(position) {
      var text = new AMap.Text({
        text:'这是文本标记',
        anchor:'center', // 设置文本标记锚点
        draggable:false,
        cursor:'pointer',
        angle:10,
        style:{
          'padding': '5px',
          'border-radius': '.25rem',
          'background-color': '#FFFFFF',
          'border-width': 0,
          'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
          'text-align': 'center',
          'font-size': '12px',
          'color': 'blue'
        },
        position: position,
        extData: {index: this.pIndex}
      });
      text.setMap(this.map);
      this.tArr.push(text);
    },
    /**
     * 点击新建
     * */
    createPolygon() {
      this.resetPrice();
      this.polyName = ''
      this.polyEditor.close();
      this.polyEditor.setTarget();
      this.polyEditor.open();
      this.showSaveButton = true

      this.editorType = 1
    },
    /**
     * 保存修改/添加围栏/修改围栏
     * */
    save() {
      this.resetPrice();
      let options = this.currentTarget.getOptions();
      let data = {
        pid: this.id,
        rail: JSON.stringify(options.path),
        name: options.extData.name || '',
        name_rail: options.extData.name_rail || '',
      }
      if(this.polyName) {
        data.name = this.polyName || ''
        data.name_rail = JSON.stringify(this.currentNamePostion) || ''
      }
      if(options.extData.id) data.id = options.extData.id  //如果有id  修改选取
      let index = options.extData.index
      this.$message.info('保存中...')
      this.$axios("POST","/index/createRail",data)
      .then(res => {
        if(res.status==1) {
          this.$message.success('保存成功')
          //设置选区的附加属性
          let extData = this.pArr[index].getExtData();
          extData.id = res.data.id;
          this.pArr[index].setExtData(extData);
          this.addMarker(index,data); //设置标点
          //往表格中添加  划区数据
          if(res.data.name) {
            let index = this.tableData.findIndex(item => {
              return item.id==res.data.id
            })
            console.log(index);
            if(index>=0) {
              this.$set(this.tableData,index,res.data)

              // this.tableData[index] = res.data;
              // this.$forceUpdate();
            }else {
              this.tableData.push(res.data)
            }
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
      this.polyEditor.close(); //关闭编辑
      this.showEditor = false;
    },
    /**
     * 取消编辑
     * */
    cancel() { //取消
      if(this.editorType==1) { //删除新建的划区
        this.currentTarget.setMap(null); //删除划区
        this.currentTarget.destroy(); //销毁内存
        this.pArr.splice(this.pIndex,1);
        this.pIndex--
        this.polyEditor.close(); //关闭编辑
        this.showEditor = false;
        this.showSaveButton = false;
        this.editorType = 0
      }else { //还原编辑之前的选区
        let id = this.currentTarget.getExtData().id;
        let index = this.currentTarget.getExtData().index;
        this.resetPolygon(id,index)
      }
    },
    /**
     * 打开链接 划区  绑定事件
     * */
    openLink() {
      console.log(this.pArr.length);
      if(this.pArr.length<=1) {
        this.$alert('当前只有一个划区，请添加后重试',{type: 'error'})
      }
      this.showEditor = false
      /*let group = new AMap.OverlayGroup([polyEditor])
      let arr = group._overlays[0]*/
      let id = this.currentTarget.getExtData().id;
      this.linkArr.push(id);
      for(let item of this.pArr) {
        item.on('click',this.selectLink)
      }
    },
    /**
     * 清除绑定的链接事件
     *  */
    clearLink() {
      for(let item of this.pArr) {
        item.off('click',this.selectLink)
      }
      this.linkArr = []
    },
    /**
     * 选择并划区并绑定
     * */
    selectLink(item) {
      item = item.target
      let extData = item.getExtData(); //当前点击的划区参数
      if(extData.id==this.linkArr[0]) {
        return this.$alert('不可选择当前选中的区域',{type: 'error'})
      }else {
        this.linkArr.push(extData.id)
        this.showPrice = true
      }
    },
    /**
     * 编辑当前选中的划区
     * */
    openEditor() {
      this.showEditor = false
      this.polyEditor.setTarget(this.currentTarget);//设置添加的划区为选中的对象
      this.polyEditor.open();//打开该划区的编辑
      this.showSaveButton = true
    },

    /**
     * 关闭编辑窗口
     * */
    closeEditorWindow(type) {
      if(type==1) {
        this.showEditor = false;
      }else if(type==2) {
        this.showPrice = false
      }
    },
    /**
     * 行政区查询
     * */
    drawBounds() {
      // 搜索所有省/直辖市信息
      if(this.keyword==='') {
        return this.$alert('请输入搜索内容',{type: 'error'})
      }
      this.districtSearch.search(this.keyword, (status, result)=> {
        if(status==='no_data') {
          return this.$message.error('未搜索到结果')
        }
        if(status==='error') {
          return this.$message.error('搜索失败，请重试')
        }
        if(result.info === 'OK') {
          this.$message.success('添加成功')
          this.addSearchPolygon(result)
        }
      })
    },
    /**
     * 添加搜索行政区的划区
     * */
    addSearchPolygon(data) {
      let that = this;
      let polygons = []
      for(let path of data.districtList[0].boundaries) {
        let polygon = new AMap.Polygon({
          path: path,
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
        })
        polygon.setExtData({index: that.pIndex})
        that.pArr.push(polygon);
        that.polyEditor.addAdsorbPolygons(polygon); //添加划区
        // let center = that.getPointsCenter(polygon.getOptions().path); //获取中心点位置
        // that.createText(center);
        that.pIndex++ //测试用
        //绑定双击事件
        polygon.on('dblclick', (dbevent) => {
          that.currentNamePostion = [
            dbevent.lnglat.lng,
            dbevent.lnglat.lat,
          ]
          that.showEditor = true
          that.currentTarget = polygon;
          let name = that.currentTarget.getExtData().name || null;
          if(name) that.polyName = name
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
        polygons.push(polygon)
      }
      //添加区域
      this.map.add(polygons)
    },
    /**
     * 选择行政区查询等级
     * */
    changeLevel(e) {
      this.districtSearch.setLevel(e);
    },
    /**
     * 编辑时添加点标记
     * */
    addMarker(index,data) {
      if(this.tArr[index]) {
        console.log('修改');
        // 自定义点标记内容
        let marker = this.tArr[index]
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = this.polyName;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        marker.setPosition(JSON.parse(data.name_rail)); //更新点标记位置
        this.$forceUpdate()
      }else {
        let marker = new AMap.Marker({
          position: JSON.parse(data.name_rail)
        });
        marker.setMap(this.map); //向地图添加标记
        // 自定义点标记内容
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = this.polyName;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        this.tArr[index] = marker
        this.$forceUpdate()
      }
    },
    /**
     * 获取围栏列时添加点标记
     * */
    addMarker2(data) {
      if(data.name_rail) {
        let marker = new AMap.Marker({
          position: JSON.parse(data.name_rail),
          extData: {id: data.id}
          // offset: new AMap.Pixel(-13, -30)
        });
        marker.setMap(this.map); //向地图添加标记
        // 自定义点标记内容
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = data.name;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        this.tArr.push(marker)
      }else {
        this.tArr.push(null)
      }
    },
    /**
     * 添加划区
     * */
    addPolygon(data) {
      let that = this;
      let polygons = []
      for(let item of data) {
        let polygon = new AMap.Polygon({
          path: JSON.parse(item.rail),
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
          fillOpacity: 0.5,
          strokeOpacity: 0.5
        })
        polygon.setExtData({
          index: that.pIndex,
          id: item.id,
          pid: item.pid,
          name: item.name,
          name_rail: item.name_rail
        })
        that.pArr.push(polygon);
        that.addMarker2(item);//添加点标记
        that.polyEditor.addAdsorbPolygons(polygon); //添加划区
        that.pIndex++ //测试用
        //绑定双击事件
        polygon.on('dblclick', (dbevent) => {
          that.currentNamePostion = [
            dbevent.lnglat.lng,
            dbevent.lnglat.lat,
          ]
          that.showEditor = true
          that.currentTarget = polygon;
          let name = that.currentTarget.getExtData().name || null;
          if(name) that.polyName = name
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
        polygons.push(polygon)
      }
      //添加区域
      this.map.add(polygons)
      this.map.setFitView(); //自动将地图缩放到合适级别

    },

    //表格操作
    selectRow(val) {
      this.currentRow = val;
    },
    /**
     * 表格点击查看按钮
     * */
    check(index,item) {
      let id = item.id
      console.log(this.pArr);
      let dbevent = this.pArr.find(item => {
        let dbId = item._opts.extData.id
        return id==dbId
      })
      if(dbevent) {
        this.currentNamePostion = JSON.parse(item.name_rail)
        this.showEditor = true
        this.currentTarget = dbevent;
        let name = this.currentTarget.getExtData().name || null;
        if(name) this.polyName = name
      }else {
        this.$alert('查看失败，请刷新重试',{type: 'error'})
      }
    },
    /**
     * 隐藏或显示划区
     * */
    changeShow(index,item) {
      let currentId = item.id
      //筛选所点击表格中的划区
      for(let value of this.pArr) {
        let extData = value.getExtData()
        if(currentId==extData.id) {
          //如果已隐藏 改为显示
          if(extData.isHide) {
            value.show() //显示划区
            extData.isHide = false
            item.isHide = false
          }else {
            value.hide() //隐藏划区
            extData.isHide = true
            item.isHide = true
          }
          value.setExtData(extData);
        }
      }
      //筛选需要显示或隐藏的标记点
      for(let value of this.tArr) {
        let extData = value.getExtData()
        if(currentId==extData.id) {
          //如果已隐藏 改为显示
          if(extData.isHide) {
            value.show() //显示标记
            extData.isHide = false
            item.isHide = false
          }else {
            value.hide() //隐藏标记
            extData.isHide = true
            item.isHide = true
          }
          value.setExtData(extData);
        }
      }
      this.$set(this.tableData,index,item)
      this.isAllCheck = this.isAllShow()
      this.$forceUpdate()
    },
    /**
     * 全部隐藏或显示
     * */
    changeAllShow(bool) {
      this.isAllCheck = !this.isAllCheck
      //划区
      for(let value of this.pArr) {
        let extData = value.getExtData()
        //如果已隐藏 改为显示
        extData.isHide = bool
        if(bool) { //隐藏
          value.hide() //隐藏划区
        }else { //显示
          value.show() //显示划区
        }
        value.setExtData(extData);
      }
      //标记
      for(let value of this.tArr) {
        let extData = value.getExtData()
        //如果已隐藏 改为显示
        extData.isHide = bool
        if(bool) { //隐藏
          value.hide() //隐藏标记
        }else { //显示
          value.show() //显示标记
        }
        value.setExtData(extData);
      }
      //表格
      for(let index in this.tableData) {
        let item = this.tableData[index]
        item.isHide = bool
        this.$set(this.tableData,index,item)
      }
    },
    /**
     * 判断是否全选
     * */
    isAllShow() {
      if(this.pArr.length<=0) return false
      let result = this.pArr.every(item => {
        return item._opts.extData.isHide
      })
      return  result
    },
    /**
     * 设置编辑区域价格
     *
     * */
    setPrice(index,item) {
      this.updatePriceId = item.id
      this.polyPrice = item.price
      this.linkArr[0] = item.zid
      this.linkArr[1] = item.fid
      this.showPrice = true
    },
    /**
     * 取消区域价格显示
     * */
    resetPrice() {
      //恢复默认 鼠标移入 移除时的颜色
      this.showCheckPrice = false
      this.strokeColor = this.defaultStrokeColor
      this.fillColor = this.defaultFillColor
      this.strokeActiveColor = this.defaultStrokeActiveColor
      this.fillActiveColor = this.defaultFillActiveColor
      //恢复已有选区 的默认颜色
      for(let item of this.pArr) {
        item.setOptions({
          strokeColor: this.strokeColor,//线条颜色
          fillColor: this.fillColor,//填充颜色
        })
      }
    },
    /**
     * 设置价格关联的区域样式
     * */
    setMapShowPrice() {
      //设置鼠标移入 移除时的颜色
      this.strokeColor = this.priceStrokeColor
      this.fillColor = this.priceStrokeColor
      this.strokeActiveColor = this.priceStrokeActiveColor
      this.fillActiveColor = this.priceFillActiveColor
      //设置已有选区的颜色
      for(let item of this.pArr) {
        let id = item._opts.extData.id
        for(let value of this.priceList) {
          if(id===value.zid || id===value.fid) {
            item._opts.extData.isGuanlian = true //设置关联用字段
            item.setOptions({
              strokeColor: this.strokeColor,//线条颜色
              fillColor: this.fillColor,//填充颜色
            })
          }
        }
      }
    },
    //接口请求
    /**
     * 获取区域列表
     * */
    getList() {
      this.$axios("POST","/index/getRail",{id: this.id})
      .then(res => {
        if(res.status==1) {
          this.tableData = res.data.filter(item => {
            return item.name!==''
          })
          console.log(this.tableData);
          this.addPolygon(res.data)
          this.$message.success('获取成功')
        }else {
          // this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    /**
     * 删除选中的划区
     * */
    deletePolygon() {
      this.$confirm('确认要删除该围栏吗？',{type: 'warning'})
      .then(con=>{
        this.$message.info('删除中')
        let data = {
          ids: this.currentTarget.getExtData().id
        }
        this.$axios("POST","/index/delRail",data)
            .then(res => {
              if(res.status==1) {
                this.$message.success('删除成功')
                this.polyName = ''
                this.showEditor = false;
                let index = this.currentTarget.getExtData().index;
                try{
                  this.tArr[index].setMap(null);//删除划区中的文本内容
                }catch (e) {}
                this.currentTarget.setMap(null); //删除划区
                this.currentTarget.destroy(); //销毁内存
                this.clearLink(); //清除绑定的链接事件

                //删除表格中的数据
                let tindex = this.tableData.findIndex(item => {
                  return item.id == data.ids
                })
                if(tindex>=0) this.tableData.splice(tindex,1)
              }else {
                this.$alert(res.msg,{type: 'error'})
              }
            })
      })

    },
    /**
     * 取消已有选区编辑
     * */
    resetPolygon(id,index) {
      this.$axios("POST","/index/getRails",{
        id
      }).then(res => {
        if(res.status==1) {
          // let index = this.currentTarget.getExtData().index;
          let path = JSON.parse(res.data.rail)
          this.currentTarget.setPath(path)
          this.polyEditor.close(); //关闭编辑
          this.showEditor = false;
          this.showSaveButton = false;
          this.editorType = 0
        }
      })
    },
    /**
     * 保存划区链接价格
     * */
    savePrice() {
      let value = this.polyPrice
      this.polyPrice = ''
      if(!value) {
        return this.$alert('请输入价格',{type: 'error'})
      }else {
        let data = {
          zid: this.linkArr[0],
          fid: this.linkArr[1],
          price: value
        }
        if(this.updatePriceId) data.id = this.updatePriceId
        this.$message.info('保存中')
        this.$axios("POST","/index/bindingRail",data)
        .then(res => {
          if(res.status==1) {
            this.showPrice = false
            this.updatePriceId = ''
            this.clearLink();//清除绑定的链接事件
            this.$alert('保存成功',{type: 'success'})
            if(this.priceId) this.getRailLinkInfo(this.priceId)
          }else {
            this.$alert(res.msg,{type: 'error'})
          }
        })
        // this.showPrice = false
        // this.$alert('保存成功，价格:'+value,{type: 'success'})
        // this.clearLink();//清除绑定的链接事件
      }
    },
    /**
     * 获取围栏关联信息
     * */
    getRailLinkInfo(id=false) {
      if(!id) {
        id = this.currentTarget.getExtData().id;
      }
      this.priceId = id
      this.$message.info('获取中')
      this.$axios("POST","/index/getRailLinkInfo",{id: id})
      .then(res => {
        if(res.status==1) {
          this.showEditor = false
          this.priceList = res.data
          this.showCheckPrice = true
          this.setMapShowPrice() //设置价格关联的区域样式
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.id = this.$route.query.id || '21';
    this.getList();
  },
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#container{
  flex: 4;
  height: 100vh;
}
.right{
  flex: 1;
  height: 100vh;
  overflow-y: auto;
}
#editor_warp,#price_warp{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.editor_cont{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 50%;
  height: 400px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 15px;
}
.close_img{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;height: auto;
  cursor: pointer;
}
.marker {
  position: absolute;
  top: -20px;
  left: 25px;
  color: #fff;
  padding: 4px 10px;
  box-shadow: 1px 1px 1px rgba(10, 10, 10, .2);
  white-space: nowrap;
  font-size: 12px;
  font-family: "";
  background-color: #25A5F7;
  border-radius: 3px;
}
.amap-icon img,.amap-marker-content img{
  width: 25px;
  height: 34px;
}

.input-card{
  position: absolute;
  top: 0;
  right: 21vw;
  z-index: 333;
}
</style>
