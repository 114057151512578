<template>
  <div style="width: 100vw;height: 100vh;background-color: #f1f1f1">
    <div class="t_warp">
      <div class="difcac">
        <div style="width: 800px;margin-bottom: 30px;">
          <el-button type="primary" icon="el-icon-arrow-left" @click="goBack()">返回</el-button>
        </div>
      </div>
      <el-transfer
          :props="{
          key: 'id',
          label: 'title'
        }"
          :titles="['车型库','线路所需车型']"
          v-model="value"
          :data="list">
        <div slot="right-footer" class="box pdLft10 dofcac" style="padding-top: 5px;">
          <el-button class="transfer-footer"  type="primary" size="small" @click="save()">保存</el-button>
        </div>
      </el-transfer>
    </div>
  </div>
</template>

<script>
export default {
  name: "transfer",
  data() {
    return {
      id: '11',
      list: [],//列表
      value: [],//选中的id
    }
  },
  methods: {
    //获取汽车类型列表
    getList() {
      this.$axios("POST","/index/getCarType",{},'加载中')
      .then(res => {
        if(res.status==1) {
          this.list = res.data
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取已选择的车型列表
    getValueList() {
      this.$axios("POST","/index/pathInfo",{
        id: this.id
      }).then(res => {
        if(res.status==1) {
          this.value = res.data.car_ids.split(',')
          this.value = this.value.map(item => {
            return Number(item)
          })
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //保存
    save() {
      if(this.value.length<=0) {
        return this.$alert('请选择所需车型',{type: 'error'})
      }
      let ids = this.value.join(',')
      this.$axios("POST","/index/pathCreateCars",{
        id: this.id,
        ids: ids
      },'保存中').then(res => {
        if(res.status==1) {
          this.$alert('保存成功',{type: 'success'})
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //返回上一页
    goBack() {
      window.location = 'https:/www.yanshixinxi.top/system/shop/index.html'
    },
  },
  created() {
    this.id = this.$route.query.id || '11'
    this.getList() //获取车型列表
    this.getValueList() //获取已选择的车型列表
  }
}
</script>

<style scoped>
  .t_warp{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  /*穿梭框样式*/
  .t_warp ::v-deep.el-transfer-panel{
    width: 265px;
  }
  .t_warp ::v-deep.el-transfer-panel__body{
    height: 335px;
  }
  .t_warp ::v-deep.el-transfer-panel__list{
    height: 335px;
  }
</style>