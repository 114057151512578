<template>
  <div>
    <el-dialog title="提示" width="70vw" :visible.sync="showPhone" top="30vh" :show-close="false" :close-on-click-modal="false">
      <div style="width: 60vw;">
        <p class="mrBtm10">手机号：</p>
        <el-input v-model="phone" placeholder="请输入手机号" type="number"></el-input>
        <div class="difcac mrTop20">
          <el-button type="primary" class="fz18" style="width: 100%;" @click="confirmPhone()">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <div class="box" style="padding: 24px;">
     <!-- <p>1，乘车前请详细了解以下乘车规则（含拼车、包车订单）；</p>
      <p>2，城际订单退款/取消规则：</p>
      <div style="padding-left: 30px;">
        <p>①用车前24小时外无责退款；</p>
        <p>②用车前8小时外退款30%；</p>
        <p>③用车前2小时外退款50%；</p>
        <p>④用车前2小时内不支持退款。</p>
      </div>
      <p>3，支付结果（含线下支付）即代表您已同意此退款规则，您已了解并熟知此支付款项的计价依据，您已同意此计价费用并无异议；</p>
      <p>4，如因司机或调度问题导致订单为无效单，您可申请全额或部分退款；</p>
      <p>5，正易出行不为您的线下结算方式及结果承担任何责任；</p>
      <p>6，正易出行不为您订单金额外的支付及结果承担任何责任；</p>
      <p>7，您可根据此支付结果记录申请等额增值税普通发票；</p>
      <p>8，您可根据此支付结果记录申请开具本次订单的行程单详情；</p>
      <p>9，请勿隐瞒乘车人数（含婴幼儿），交通法规定，乘车人按自然人计算，若您隐瞒实际乘车人数，司机有权拒载，此车费不退还；</p>
      <p>10，请提前与司机或运力商沟通行李物品，允许每人携带24-26寸行李箱/1个，若您隐瞒实际携带行李物品，司机有权拒载，此车费不退还；</p>
      <p>11，您已知悉乘车规则，确保您不会携带易燃、易爆、易腐蚀、枪支、管制刀具等法律规定的危险物品乘车，不会携带异味、易碎品及容易损坏的名贵物品乘车，否则因此带来的全部后果及责任，由您个人承担；</p>
      <p>12，请勿携带宠物上车，提前与司机或运力商沟通，否则司机有权拒载，此车费不退还；</p>
      <p>13，若您不接受以上支付规则，请在退出此页面后，通知运营商取消本次订单。</p>-->
      <div v-html="content"></div>
      <div class="mrTop20">
        <el-button type="primary" style="width: 100%;" @click="agree()">同意并退出</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "offline",
  data() {
    return {
      showContent: false,
      showPhone: false,
      phone: '',
      showLodaing: false,
      content: '',
    }
  },
  methods: {
    getContent() {
      this.$axios("POST",'/index/getxuzhi',{})
          .then(res => {
            if(res.status==1) {
              this.content = res.data
            }
          })
    },
    agree() {
      this.showLodaing = true
      this.$axios("POST","/index/userOrderConAffirm",{
        mobile: this.phone,
        status: 1
      }).then(res => {
        this.showLodaing = false
        const ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE') > 0) {
          if (ua.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
          } else {
            window.open('', '_top');
            window.top.close();
          }
        } else {
          window.opener = null;
          window.open('', '_self', '');
          window.close();
        }
      })

    },
    confirmPhone() {
      if(!this.phone) {
        return this.$message.error('请输入手机号')
      }
      this.showPhone = false
      // this.showContent
    }
  },
  created() {
    document.title = '线下支付规则'
    this.getContent()
  }
}
</script>

<style scoped>
  ::v-deep .el-dialog__body {
    padding-top: 10px;
  }
</style>