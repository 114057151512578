<template>
  <div class="box" style="padding: 14px;">
    <!--地图-->
    <div class="difb " style="margin-top: 10px;">
      <!--左边的内容-->
      <div style="width: 38%;" class="pdTop10 box pdLft20">
        <!--新增订单-->
        <!--<div>
          <el-radio v-model="obj.type" label="1" @change="changeType">城际拼车</el-radio>
          <el-radio v-model="obj.type" label="2" @change="changeType">城际包车</el-radio>
          <el-radio v-model="obj.type" label="3" @change="changeType">小件快递</el-radio>
          <el-radio v-model="obj.type" label="4" @change="changeType">市内用车</el-radio>
        </div>-->
        <!--乘车人  电话-->
        <div class="dif alc mrTop10">
          <div class="dif alc">
            <div>乘车人：</div>
            <div style="width: 150px;"><el-input placeholder="请输入" v-model="obj.name"></el-input></div>
          </div>
          <div class="dif alc mrLft20">
            <div>电话：</div>
            <div style="width: 150px;"><el-input placeholder="请输入" v-model="obj.mobile"></el-input></div>
          </div>
        </div>
        <!--出发地和目的地-->
        <div class="dif alc">
          <div class="mrRit30">
            <!--出发地-->
            <div class="dif alc mrTop10">
              <div style="width: 60px;">出发地：</div>
              <el-select @change="changePlace"
                         style="width: 100px;" v-model="currentPlace" placeholder="请选择">
                <el-option
                    v-for="item in place"
                    :key="item.id"
                    :label="item.place"
                    :value="item.place">
                </el-option>
              </el-select>
              <!--<div style="width: 300px;"><el-input placeholder="请输入" v-model="obj.place"></el-input></div>-->
              <div style="width: 300px;">
                <el-autocomplete style="width: 250px;"
                                 popper-class="my-autocomplete"
                                 placeholder="请输入搜索内容"
                                 @focus="setType(1)"
                                 v-model="currentSelect.p"
                                 @blur="setValue()"
                                 :fetch-suggestions="(val,cb)=>{querySearchAsync(val,cb,1)}"
                                 :trigger-on-focus="false"
                                 @select="handleSelect($event,1)"
                >
                  <template slot-scope="{ item }">
                    <div class="name">{{ item.value }}</div>
                    <span
                        class="addr"
                    >{{ item.district }}{{ item.address.length>0?item.address:'' }}</span>
                  </template>
                </el-autocomplete>
              </div>
            </div>
            <!--目的地-->
            <div class="dif alc mrTop10">
              <div style="width: 60px;">目的地：</div>
              <el-select @change="changeDestination" style="width: 100px;" v-model="currentDestination" placeholder="请选择">
                <el-option
                    v-for="item in destination"
                    :key="item.id"
                    :label="item.destination"
                    :value="item.destination">
                </el-option>
              </el-select>
              <!--<div style="width: 300px;"><el-input placeholder="请输入" v-model="obj.destination"></el-input></div>-->
              <div style="width: 300px;">
                <el-autocomplete style="width: 250px;"
                   @focus="setType(2)"
                   popper-class="my-autocomplete"
                   placeholder="请输入搜索内容"
                   v-model="currentSelect.d"
                   @blur="setValue()"
                   :fetch-suggestions="(val,cb)=>{querySearchAsync(val,cb,2)}"
                   :trigger-on-focus="false"
                   @select="handleSelect($event,2)"
                >
                  <template slot-scope="{ item }">
                    <div class="name">{{ item.value }}</div>
                    <span
                        class="addr"
                    >{{ item.district }}{{ item.address.length>0?item.address:'' }}</span>
                  </template>
                </el-autocomplete>
              </div>
            </div>
          </div>
          <el-button type="primary" @click="changeAddress()">换</el-button>
        </div>
        <!--出发时间-->
        <div class="dif alc mrTop10">
          <div>出发时间：</div>
          <!--<el-date-picker
              v-model="timePicker" :clearable="false"
              type="datetime"
              placeholder="选择日期时间"
              align="right"
              :picker-options="pickerOptions">
          </el-date-picker>-->
          <el-date-picker
              style="width: 140px;"
              v-model="obj.datetimes"
              align="right"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
          </el-date-picker>
          <div style="width: 100px;">
            <el-input v-model="hourTime" placeholer="请输入" maxlength="4" type="text"></el-input>
          </div>
        </div>
        <!--拼车人数-->
        <div class="dif alc mrTop10" v-if="obj.type==1 || obj.type==4">
          <div>拼车人数：</div>
          <div style="width: 200px;"><el-input @input="changePrice" placeholder="请输入" type="number" v-model="obj.num"></el-input></div>
        </div>
        <!--包车人数-->
        <div class="dif alc mrTop10" v-if="obj.type==2">
          <div>包车人数：</div>
          <div style="width: 200px;"><el-input @input="changePrice" placeholder="请输入" type="number" v-model="obj.num"></el-input></div>
        </div>
        <!--价格-->
        <div class="dif alc mrTop10" v-if="obj.type==1 || obj.type==4">
          <div>拼车价格：</div>
          <div style="width: 200px;"><el-input placeholder="请输入" v-model="obj.amount" type="number"></el-input></div>
        </div>
        <!--价格-->
        <div class="dif alc mrTop10" v-if="obj.type==2">
          <div>包车价格：</div>
          <div style="width: 200px;"><el-input placeholder="请输入" v-model="obj.amount2" type="number"></el-input></div>
        </div>
        <!--价格-->
        <div class="dif alc mrTop10" v-if="obj.type==3">
          <div>包裹价格：</div>
          <div style="width: 200px;"><el-input placeholder="请输入" v-model="amount3" type="number"></el-input></div>
        </div>
        <!--多选-->
        <div class="mrTop10">
          <el-checkbox label="1" value="1" v-model="obj.is_front">前排座位</el-checkbox>
          <el-checkbox label="2" value="1" v-model="obj.is_invoice">需要发票</el-checkbox>
          <el-checkbox label="3" value="1" v-model="obj.is_aircraft">赶飞机</el-checkbox>
        </div>
        <!--备注详情-->
        <div class="dif mrTop10 alc">
          <div>备注详情：</div>
          <div style="width: 300px;"><el-input placeholder="请输入"  v-model="obj.remark" ></el-input></div>
        </div>
        <!--保存-->
        <div class="mrTop10 difcac">
            <el-button type="primary"  @click="upDateOrder()">保存修改</el-button>
        </div>
      </div>
      <!--右边的地图-->
      <div id="map3"></div>
    </div>
    <!--司机列表-->
    <el-dialog
        width="70vw"
        title="指派司机"
        :visible.sync="showDrive" top="20vh">
      <div>
        <!--订单信息-->
        <div v-if="setDriceId">
          <div class="dif alc">
            <div>乘车人：<span class="bold">{{setDriceId.name}}</span></div>
            <div class="mrLft30">乘车电话：<span class="bold">{{setDriceId.mobile}}</span></div>
            <div class="mrLft30">用车需求：<span class="bold">{{setDriceId.type}}/{{setDriceId.num}}人</span></div>
          </div>
          <div class="dif alc mrTop20 mrBtm20">
            <div>起始地：<span class="bold">{{setDriceId.place}}</span></div>
            <div class="mrLft30">目的地：<span class="bold">
              {{setDriceId.destination}}
            </span></div>
          </div>
        </div>
        <!--表格-->
        <el-table
            height="40vh"
            :stripe="true"
            highlight-current-row
            ref="singleTable"
            :data="driveList"
            style="width: 100%">
          <!--司机名称-->
          <el-table-column
              prop="driverName"
              label="司机名称"
              width="80">
          </el-table-column>
          <!--电话-->
          <el-table-column
              prop="tel"
              label="司机电话"
              width="120">
          </el-table-column>
          <!--车牌号-->
          <el-table-column
              prop="carNo"
              label="车牌号"
              width="80">
          </el-table-column>
          <!--车辆类型-->
          <el-table-column
              prop="carName"
              label="车型"
              width="110">
          </el-table-column>
          <!--派单情况-->
          <el-table-column
              prop="asdasd"
              label="派单情况"
              width="110">
          </el-table-column>
          <!--报班时间-->
          <el-table-column
              prop="bao_time"
              label="报班时间"
              width="110">
          </el-table-column>
          <el-table-column
              prop="location"
              label="报班位置"
              width="180">
          </el-table-column>
          <!--操作-->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="pai(scope.$index, scope.row)">指派</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "update",
  props: {
    updateId: '',
  },
  watch: {
    updateId(newValue) {
      console.log(newValue);
    }
  },
  data() {
    return {
      id: '134',
      info: {
        place: '',//起始地
        destination: '',//结束地
      },
      map1: null, //第一个地图
      geocoder: null, //逆向地理编码
      map2: null, //第二个地图
      pArr1: [],//第一个地图的划区
      pArr2: [],//第二个地图的划区
      priceList: [], //选择的两个关联划区
      nameList: [null,null],

      hourTime: '',
      currentPlace: '',//当前出发地
      currentDestination: '',//当前结束第
      place: [],//出发地
      destination: [],//结束地
      showUpdate: false,//显示修改订单

      /*主要参数*/
      pageSize: 5,//limit
      pages: 1,//当前页数
      totalPage: 1,//总页数
      setId: '',//设置id
      list: [],//订单列表
      keyword: '',//搜索关键词
      currentRow: null,//当前选中的行
      obj: {
        type: '1',//订单类型
        name: '',//乘车人
        mobile: '',//电话
        place: '',//出发地
        destination: '',//目的地
        time: '',
        datetimes: '',
        times: '',
        num: 1,
        nums: '',
        amount: '',//价格
        amount2: '',//价格
        is_front: '',//前排座位
        is_invoice: '',//需要发票
        is_aircraft: '',//赶飞机
        remark: '', //备注
        lat: '',
        lng: '',
        dao_lat: '',
        dao_lng: '',
        pin_type: 1,//1拼车价格 2包车价格
      },
      amount: '50',//拼车价格
      amount2: '',//包车价格
      amount3: '',//包车价格
      timePicker: '',
      tArr: [],//搜索地址所创建的标记点 数组
      currentType: 1,//当前激活的输入框 1出发地 2目的地
      currentSelect: { //已经选中的
        p: '',
        d: '',
        lat: '',
        lng: '',
        dao_lat: '',
        dao_lng: '',
      },
      searchFrom: {
        p: '',//出发地
        d: '',//目的地
      },
      isSelect: false,//是否已选择
      setDriceId: null,//需要指派的订单信息
      showDrive: false, //显示司机列表
      driveList: [],//司机列表
      /*主要参数*/

      //测试用
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],

      railLogInfo: { //围栏绑定信息
        price: '',
        five_price: '',
        seven_price: '',
        parcel_price: '',
      },

      defaultStrokeColor: '#006600', //默认未选中时线条颜色
      defaultFillColor: '#FFAA00', //默认未选中时填充颜色
      defaultStrokeActiveColor: '#000566', //默认选中时线条颜色
      defaultFillActiveColor: '#b69045', //默认选中时填充颜色

      priceStrokeColor: '#fa3534', //查看价格时未选中时线条颜色
      priceFillColor: '#409EFF', //查看价格时未选中时填充颜色
      priceStrokeActiveColor: '#1aff00', //查看价格时未选中时线条颜色
      priceFillActiveColor: '#224a6c', //查看价格时未选中时填充颜色

      strokeColor: '#006600', //未选中时线条颜色
      fillColor: '#FFAA00', //未选中时填充颜色
      strokeActiveColor: '#000566', //未选中时线条颜色
      fillActiveColor: '#b69045', //选中时填充颜色
      //时间选择器快捷操作
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '明天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {
    // 保存修改订单
    upDateOrder() {
      let data = JSON.parse(JSON.stringify(this.obj))
      data.is_front = Number(this.obj.is_front)
      data.is_invoice = Number(this.obj.is_invoice)
      data.is_aircraft = Number(this.obj.is_aircraft)
      /* var date = new Date(this.timePicker);
       var Y = date.getFullYear() + '-';
       var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
       var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
       var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
       var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
       data.datetimes = Y+M+D //年月日
       data.times = h+m*/
      data.times = '00:00'
      if(this.hourTime) data.times = this.hourTime.substring(0,2) + ':' + this.hourTime.substring(2,4)
      if(this.obj.type==3) data.amount = this.amount3 //包裹价格
      this.$axios("POST","/index/updateOrder",data,'加载中')
      .then(res => {
        if(res.status==1) {
          this.$message.success('保存成功')
          this.$emit('updateSuccess',1)
        }else {
          this.$alert(res.msg,{type: "error"})
        }
      })
    },
    // 初始化数据
    initData(id) {
      this.$axios("POST","/index/orderDetail",{id: id},'加载中')
      .then(res => {
        if(res.status==1) {
          this.obj = res.data
          this.amount = res.data.amount
          this.hourTime = res.data.times.substring(0,2) + res.data.times.substring(3,5)
          this.obj.is_front = Boolean(Number(res.data.is_front))
          this.obj.is_invoice = Boolean(Number(res.data.is_invoice))
          this.obj.is_aircraft = Boolean(Number(res.data.is_aircraft))
          this.currentDestination = res.data.destination_s
          this.currentPlace = res.data.place_s
          this.currentSelect = {
            p: res.data.place,
            d: res.data.destination,
            lat: Number(res.data.lat),
            lng: Number(res.data.lng),
            dao_lat: Number(res.data.dao_lat),
            dao_lng: Number(res.data.dao_lng),
          }
          this.changePlace(res.data.place_s,true)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    /**
     * 初始化地图
     * */
    init() {
      let that = this
      this.map1 = new AMap.Map("map3", {
        center: [117.078681,36.673923],//中心点
        zoom: 8,//缩放等级
      });
      this.geocoder = new AMap.Geocoder();
      //地图点击事件
      this.map1.on('click', (data)=> {
        let lnglat = [data.lnglat.lng,data.lnglat.lat]
        this.geocoder.getAddress(lnglat, (status, result)=> {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            if(this.currentType===1) {
              this.obj.place = result.regeocode.formattedAddress
              this.obj.lat = data.lnglat.lat
              this.obj.lng = data.lnglat.lng
              this.currentSelect.p = result.regeocode.formattedAddress
              this.currentSelect.lat = data.lnglat.lat
              this.currentSelect.lng = data.lnglat.lng
            }else {
              this.obj.destination = result.regeocode.formattedAddress
              this.obj.dao_lat = data.lnglat.lat
              this.obj.dao_lng = data.lnglat.lng
              this.currentSelect.d = result.regeocode.formattedAddress
              this.currentSelect.dao_lat = data.lnglat.lat
              this.currentSelect.dao_lng = data.lnglat.lng
            }
            this.getPrice() //计算价格
          }
        })
      })
      this.map1.setFitView(); //自动将地图缩放到合适级别
    },
    /**
     * 获取司机列表
     * */
    getDriveList() {
      this.$axios("POST","/index/getDriversList",{id: this.id})
          .then(res => {
            if(res.status==1) this.driveList = res.data
          })
    },
    /**
     * 添加划区
     * @param type 1始发地 2目的地
     * */
    addPolygon(data,type) {
      let that = this;
      let polygons = []
      for(let item of data) {
        let polygon = new AMap.Polygon({
          path: JSON.parse(item.rail),
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
          fillOpacity: 0.5,
          strokeOpacity: 0.5
        })
        polygon.setExtData({
          id: item.id,
          pid: item.pid,
          name: item.name,
          name_rail: item.name_rail,
          mapIndex: type
        })
        that.addMarker2(item,type);//添加点标记
        //绑定双击事件
        polygon.on('click', (dbevent) => {
          that.setMapShowPrice(dbevent) //选择划区
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
        polygons.push(polygon)
      }
      //添加区域
      if(type==1) { //始发地
        this.map1.add(polygons)
        this.pArr1 = polygons
      }else { //结束地
        this.map2.add(polygons)
        this.pArr2 = polygons
      }
    },
    /**
     * 获取围栏列时添加点标记
     * */
    addMarker2(data,type) {
      console.log(data);
      if(data.location) {
        let marker = new AMap.Marker({
          position: [data.location.lng,data.location.lat],
          extData: {
            name: data.name,
            location: data.location
          }
        });
        // 设置点击事件
        marker.on('click',(event) => {
          let target = event.target //点击的标点对象
          let extData = target.getExtData() //选区的自定义属性
          this.handleSelect(extData,this.currentType) //设置选中的地址
        })
        if(type==1) {
          marker.setMap(this.map1); //向地图添加标记
          this.tArr.push(marker)
        }else {
          marker.setMap(this.map2); //向地图添加标记
        }
        // 自定义点标记内容
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = data.name;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
      }
      this.map1.setFitView(); //自动将地图缩放到合适级别
      // this.map2.setFitView(); //自动将地图缩放到合适级别
    },
    /**
     * 搜索订单
     * */
    searchOrder(isSearch=true) {
      if(this.keyword.trim()==='' && isSearch) {
        return this.$alert('请输入搜索内容',{type: 'error'})
      }
      this.obj.mobile = this.keyword
      this.$axios("POST","/index/telGetOrderList",{
        mobile: this.keyword,
        pages: this.pages,
        pageSize: this.pageSize
      },'加载中').then(res => {
        if(res.status==1) {
          this.totalPage = Math.ceil(res.count/this.pageSize)
          this.list = res.data
        }else {
          // this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    /**
     * 复制订单
     * */
    copy(index,item) {
      this.obj.num = item.num | ''
      this.obj.amount = item.amount || ''
      this.obj.type = item.types || ''
      this.obj.name = item.name || ''

      this.obj.place = item.place || ''
      this.obj.destination = item.destination || ''
      this.obj.lat = item.lat || ''
      this.obj.lng = item.lng || ''
      this.obj.dao_lat = item.dao_lat || ''
      this.obj.dao_lng = item.dao_lng || ''

      this.currentSelect.p = item.place || ''
      this.currentSelect.d = item.destination || ''
      this.currentSelect.lat = item.lat || ''
      this.currentSelect.lng = item.lng || ''
      this.currentSelect.dao_lat = item.dao_lat || ''
      this.currentSelect.dao_lng = item.dao_lng || ''


      this.obj.is_aircraft = item.is_aircraft || ''
      this.obj.is_front = item.is_front || ''
      this.obj.is_invoice = item.is_invoice || ''
      this.obj.remark = item.remark || ''

      this.currentPlace = item.city_name
      this.currentDestination = item.dao_city_name
      this.timePicker = new Date(item.depart_time)
    },
    /**
     * 设置要修改的订单
     * */
    setUpdate(index,item) {

    },
    isBlack() { //判断是否拉黑
      this.$axios("POST","/index/isBlack",{mobile: this.obj.mobile},'加载中')
          .then( res=> {
            if(res.status==1) {
              this.save()
            }else {
              this.$confirm('该号码已加入黑名单，是否继续下单',{type: 'error',confirmButtonText: '继续下单'})
                  .then(con => {
                    this.save()
                  })
            }
          })
    },
    /**
     * 保存记录
     * */
    save() {
      let data = JSON.parse(JSON.stringify(this.obj))
      data.is_front = Number(this.obj.is_front)
      data.is_invoice = Number(this.obj.is_invoice)
      data.is_aircraft = Number(this.obj.is_aircraft)
      /* var date = new Date(this.timePicker);
       var Y = date.getFullYear() + '-';
       var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
       var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
       var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
       var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
       data.datetimes = Y+M+D //年月日
       data.times = h+m*/
      data.times = '00:00'
      data.destination_city_name = this.currentPlace
      data.dao_city_name = this.currentDestination
      if(this.hourTime) data.times = this.hourTime.substring(0,2) + ':' + this.hourTime.substring(2,4)
      if(this.obj.type==3) data.amount = this.amount3 //包裹价格
      this.$axios("POST","/index/save",data,'加载中')
          .then(res => {
            if(res.status==1) {
              this.$message.success('提交成功')
              setTimeout(() => {
                this.searchOrder(false)
              },1500)
            }else {
              this.$alert(res.msg,{type: "error"})
            }
          })
    },
    /**
     * 选择订单类型
     * */
    changeType(e) {
      this.obj.place = ''
      this.obj.destination = ''
      this.obj.lat = ''
      this.obj.lng = ''
      this.obj.dao_lng = ''
      this.obj.dao_lat = ''
      this.currentPlace = ''
      this.currentDestination = ''
      this.currentSelect = {
        p: '',
        d: '',
        lat: '',
        lng: '',
        dao_lat: '',
        dao_lng: '',
      }
      this.getPlace()
    },
    /**
     * 获取出发地列表
     * */
    getPlace() {
      let type = '1'
      if(this.obj.type==4) type = 2
      this.$axios("POST","/index/getPathInfo",{type: type,id: 1})
          .then(res => {
            if(res.status==1) {
              // 对象完全匹配去重
              // let newArr = [...new Set(res.data.map(v  => JSON.stringify(v)))].map(s => JSON.parse(s));
              //去重
              let arr = []
              for(let item of res.data) {
                let isPush = true
                for(let item2 of arr) {
                  if(item2.place===item.place) {
                    isPush = false
                  }
                }
                if(isPush) arr.push(item)
              }
              this.place = arr
            }
          })
    },
    /**
     * 选择出发地 清空目的地 获取目的地
     * */
    changePlace(e,isChange) {
      if(!isChange) {
        this.currentDestination = ''
        this.obj.destination = ''
        this.currentSelect.p = ''
      }
      let type = 1
      if(this.obj.type==4) type = 2
      this.$axios("POST","/index/screenDestination",{
        place: e,
        // id: this.id,
        id: 1,
        type: type
      },'加载中').then(res => {
        if(res.status==1) {
          this.destination = res.data
        }else {
          this.$message.error(res.msg)
        }
      })
      console.log(e);
    },
    changeDestination(e) {
      this.currentSelect.d = ''
    },
    /**
     * 地址交换
     * */
    changeAddress() {
      let center = this.obj.place
      this.obj.place = this.obj.destination
      this.obj.destination = center
      let lng = this.obj.lng
      this.obj.lng = this.obj.lat
      this.obj.lat = lng
      let dao_lng = this.obj.dao_lng
      this.obj.dao_lng = this.obj.dao_lat
      this.obj.dao_lat = dao_lng

      let center2 = this.currentPlace
      this.currentPlace = this.currentDestination
      this.currentDestination =  center2
      this.currentSelect = {
        p: this.obj.place,
        d: this.obj.destination,
        lat: this.obj.lat,
        lng: this.obj.lng,
        dao_lat: this.obj.dao_lat,
        dao_lng: this.obj.dao_lng,
      }

      if(this.currentPlace) this.changePlace(this.currentPlace,true)
    },
    /**
     * 关键词搜索  地址
     * @param type 1出发地 2目的地
     * @param val 输入的内容
     * @param cb callBack 搜索的结果通过这个回调来返回出去
     * */
    /*querySearchAsync(val, cb, type) {
      let that = this
      let options = {
        pageSize: 15, // 单页显示结果条数
        pageIndex: 1, // 页码
        citylimit: true,  //是否强制限制在设置的城市内搜索
      }
      if(type===1) { //出发地
        options.city = this.currentPlace || ''
      }else { //目的地
        options.city = this.currentDestination || ''
      }
      let placeSearch = new AMap.PlaceSearch(options);
      // 根据关键字进行搜索
      placeSearch.search(val, function (status, result) {
        console.log(status);
        console.log(result);
        // 搜索成功时，result即是对应的匹配数据
        let resAddress = [];
        if (status == "complete") {
          resAddress = result.poiList.pois;
          resAddress = result.poiList.pois.filter(item => {
            return item.location
          })
        }
        // 清空地图
        if(resAddress.length>0) {
          for(let item of that.tArr) {item.setMap(null)}
          // 将要选择后显示的值字段名设置为value，其他字段名会选择后不回显名称
          for (const item of resAddress) {
            item.value = item.name;
            that.addMarker2(item,1) //设置标记点
          }
          if(that.currentType==1) { //出发地
            if(that.obj.lat==='' && that.obj.lng==='') {
              console.log('自动选择出发地');
              that.currentSelect.p = resAddress[0].name
              that.currentSelect.lat = resAddress[0].location.lat
              that.currentSelect.lng = resAddress[0].location.lng
              that.obj.place = resAddress[0].name
              that.obj.lat = resAddress[0].location.lat
              that.obj.lng = resAddress[0].location.lng
              that.getPrice() //计算价格
            }
          }else { //目的地
            if(that.obj.dao_lat==='' && that.obj.dao_lng==='') {
              console.log('自动选择目的地');
              that.currentSelect.d = resAddress[0].name
              that.currentSelect.dao_lat = resAddress[0].location.lat
              that.currentSelect.dao_lng = resAddress[0].location.lng
              that.obj.destination = resAddress[0].name
              that.obj.dao_lat = resAddress[0].location.lat
              that.obj.dao_lng = resAddress[0].location.lng
              that.getPrice() //计算价格
            }
          }
        }
        cb(resAddress);
      });
    },*/
    querySearchAsync(val, cb, type) {
      let that = this
      let options = {
        pageSize: 15, // 单页显示结果条数
        pageIndex: 1, // 页码
        citylimit: true,  //是否强制限制在设置的城市内搜索
      }
      let url = 'https://restapi.amap.com/v3/assistant/inputtips'
      url = url + `?key=${'3cb48306203ce04a1a05cb5fa8060c9b'}&citylimit=true`
      url = url + `&keywords=${val}`
      if(type===1) { //出发地
        url = url + `&city=${this.currentPlace || ''}`
      }else { //目的地
        url = url + `&city=${this.currentDestination || ''}`
      }
      let data = {
        // val: type==1?this.currentPlace + val:this.currentDestination+val
        val: val,
        type: type
      }
      if(type===1) { //出发地
        data.city = this.currentPlace || ''
      }else { //目的地
        data.city = this.currentDestination || ''
      }
      this.$axios('POST','/index/gaode',data)
          .then(res => {
            res = res.data
            if(res.infocode==='10000' && res.status==='1') {
              let resAddress = [];
              resAddress = res.tips.filter(item => {
                return item.location
              })
              resAddress = resAddress.map(item =>{
                let obj = item
                let arr = []
                try {
                  arr = item.location.split(',')
                }catch (e) {}
                obj.location = {
                  lat: arr[1],
                  lng: arr[0],
                }
                obj.value = item.name
                // item.location
                return obj
              })
              console.log(resAddress);
              // 清空地图
              if(resAddress.length>0) {
                for(let item of that.tArr) {item.setMap(null)}
                // 将要选择后显示的值字段名设置为value，其他字段名会选择后不回显名称
                for (const item of resAddress) {
                  item.value = item.name;
                  that.addMarker2(item,1) //设置标记点
                }
                if(that.currentType==1) { //出发地
                  if(that.obj.lat==='' && that.obj.lng==='') {
                    console.log('自动选择出发地');
                    that.currentSelect.p = resAddress[0].name
                    that.currentSelect.lat = resAddress[0].location.lat
                    that.currentSelect.lng = resAddress[0].location.lng
                    that.obj.place = resAddress[0].name
                    that.obj.lat = resAddress[0].location.lat
                    that.obj.lng = resAddress[0].location.lng
                    that.getPrice() //计算价格
                  }
                }else { //目的地
                  if(that.obj.dao_lat==='' && that.obj.dao_lng==='') {
                    console.log('自动选择目的地');
                    that.currentSelect.d = resAddress[0].name
                    that.currentSelect.dao_lat = resAddress[0].location.lat
                    that.currentSelect.dao_lng = resAddress[0].location.lng
                    that.obj.destination = resAddress[0].name
                    that.obj.dao_lat = resAddress[0].location.lat
                    that.obj.dao_lng = resAddress[0].location.lng
                    that.getPrice() //计算价格
                  }
                }
              }
              console.log(resAddress);
              cb(resAddress)
            }else {
              cb([])
            }
          }).catch(err => {
        cb([])
      })
      return
      let placeSearch = new AMap.PlaceSearch(options);
      // 根据关键字进行搜索
      placeSearch.search(val, function (status, result) {
        console.log(status);
        console.log(result);
        // 搜索成功时，result即是对应的匹配数据
        let resAddress = [];
        if (status == "complete") {
          resAddress = result.poiList.pois;
          resAddress = result.poiList.pois.filter(item => {
            return item.location
          })
        }

        cb(resAddress);
      });
    },
    /**
     * 选中搜索建议
     * */
    handleSelect(item,type) {
      if(type==1) { //出发地
        this.obj.place = item.name
        this.obj.lat = item.location.lat
        this.obj.lng = item.location.lng
        this.currentSelect.p = item.name
        this.currentSelect.lat = item.location.lat
        this.currentSelect.lng = item.location.lng
      }else { //目的地
        this.obj.destination = item.name
        this.obj.dao_lat = item.location.lat
        this.obj.dao_lng = item.location.lng
        this.currentSelect.d = item.name
        this.currentSelect.dao_lat = item.location.lat
        this.currentSelect.dao_lng = item.location.lng
      }
      this.getPrice() //计算价格
    },
    /**
     * 设置当前输入框的类型
     * @param type 1出发地 2目的地
     * */
    setType(type) {
      this.currentType = type
      this.currentSelect = {
        // p: this.obj.place,
        // d: this.obj.destination,
        p: this.currentSelect.p,
        d: this.currentSelect.d,
        lat: this.obj.lat,
        lng: this.obj.lng,
        dao_lat: this.obj.dao_lat,
        dao_lng: this.obj.dao_lng,
      }
    },
    /**
     * 判断是否有选择
     * */
    setValue() {
      setTimeout(() => {
        if(this.currentSelect.p !== this.obj.place) {
          this.currentSelect.p = this.obj.place
          this.currentSelect.lat = this.obj.lat
          this.currentSelect.lng = this.obj.lng
        }
        if(this.currentSelect.d !== this.obj.destination) {
          this.currentSelect.d = this.obj.destination
          this.currentSelect.dao_lat = this.obj.dao_lat
          this.currentSelect.dao_lng = this.obj.dao_lng
        }
      },400)
    },
    /**
     * 切换页数
     * */
    changeSize(e) {
      this.pages = e
      this.searchOrder()
    },
    /**
     * 获取价格
     * */
    getPrice() {
      let data = {
        lat: this.obj.lat,
        lng: this.obj.lng,
        dao_lat: this.obj.dao_lat,
        dao_lng: this.obj.dao_lng
      }
      if(data.lat && data.dao_lat) {
        this.$axios("POST","/index/getPricesss",data)
            .then(res => {
              if(res.status==1) {
                this.$message.success('计算成功')
                if(this.obj.num>0) {
                  this.obj.amount = (res.data.price * this.obj.num).toFixed(2)
                  this.obj.amount2 = (res.data.price2 * 1).toFixed(2)
                }else {
                  this.obj.amount = res.data.price
                  this.obj.amount2 = res.data.price2
                }
                this.amount = res.data.price
                this.amount2 = res.data.price2
                this.amount3 = res.data.bao_price
              }else {
                this.$message.error(res.msg)
              }
            })
      }else {
        console.log('信息不全，无法计算');
      }
    },
    /**
     * 设置司机
     * */
    setDrive(item) {
      this.showDrive = true
      this.setDriceId = item
    },
    /**
     * 指派 司机
     * */
    pai(index,item) {
      this.$axios("POST","/index/pai",{
        id: this.setDriceId.id,
        bao_id: item.id
      },'加载中').then(res => {
        if(res.status==1) {
          this.showDrive = false
          this.$message.success('指派成功')
          setTimeout(() => {
            this.searchOrder()
          },1200)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    /**
     * 设置价格
     * */
    changePrice() {
      if(this.amount) {
        this.obj.amount = (this.obj.num * this.amount).toFixed(2)
      }
    },
    /*
    * 以下没有用到，直接复制的 备用
    * */

    /**
     * 设置价格关联的区域样式
     * */
    setMapShowPrice(e) {
      let target = e.target //点击的地图对象
      let mapIndex = e.target.getExtData().mapIndex //目标所处的地图
      let arr = []
      let targetId = e.target.getExtData().id //点击的地图id
      if(mapIndex==2) { //第二个地图
        arr = this.pArr2
        this.priceList[1] = targetId
        this.nameList[1] = e.target.getExtData().name
      }else { //第一个地图
        arr = this.pArr1
        this.priceList[0] = targetId
        this.nameList[0] = e.target.getExtData().name
      }



      //设置鼠标移入 移除时的颜色
      this.strokeColor = this.priceStrokeColor
      this.fillColor = this.priceStrokeColor
      this.strokeActiveColor = this.priceStrokeActiveColor
      this.fillActiveColor = this.priceFillActiveColor
      //还原其他选取的颜色
      for(let item of arr) {
        let id = item._opts.extData.id
        if(id!=targetId) {
          item._opts.extData.isGuanlian = false //设置关联用字段
          item.setOptions({
            strokeColor: this.defaultStrokeColor,//线条颜色
            fillColor: this.defaultFillColor,//填充颜色
          })
        }
      }
      target._opts.extData.isGuanlian = true //设置关联用字段
      target.setOptions({
        strokeColor: this.strokeColor,//线条颜色
        fillColor: this.fillColor,//填充颜色
      })


      return

    },
    /**
     * 保存划区链接价格
     * */
    savePrice() {
      let data = this.railLogInfo
      data.zid = this.priceList[0]
      data.fid = this.priceList[1]
      if(!data.price || data.price<=0) { //拼车价格
        return this.$alert('请输入正确的拼车价格',{type: 'error'})
      }else if(!data.five_price || data.five_price<=0) { //五座价格
        return this.$alert('请输入正确的5座价格',{type: 'error'})
      }else if(!data.seven_price || data.seven_price<=0) { //七座
        return this.$alert('请输入正确的7座价格',{type: 'error'})
      }else if(!data.parcel_price || data.parcel_price<=0) { //包裹
        return this.$alert('请输入正确的包裹价格',{type: 'error'})
      }
      this.$axios("POST","/index/bindingRail",data,'保存中')
          .then(res => {
            if(res.status==1) {
              this.showPrice = false
              this.$alert('保存成功',{type: 'success'})
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    /**
     * 删除价格
     * */
    delPrice() {
      this.$confirm('确认要删除该价格吗？',{type: 'warning'})
          .then(con => {
            this.$axios("POST","/index/delRailLog",{
              zid: this.priceList[0],
              fid: this.priceList[1],
            },'删除中')
                .then(res => {
                  if(res.status==1) {
                    this.showPrice = false
                    this.$alert('删除成功',{type: 'success'})
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })
    },
    /**
     * 在创建划区的中心点创建text文本
     * */
    createText(position) {
      var text = new AMap.Text({
        text:'这是文本标记',
        anchor:'center', // 设置文本标记锚点
        draggable:false,
        cursor:'pointer',
        angle:10,
        style:{
          'padding': '5px',
          'border-radius': '.25rem',
          'background-color': '#FFFFFF',
          'border-width': 0,
          'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
          'text-align': 'center',
          'font-size': '12px',
          'color': 'blue'
        },
        position: position,
        extData: {index: this.pIndex}
      });
      text.setMap(this.map);
      this.tArr.push(text);
    },
    /**
     * 点击新建
     * */
    createPolygon() {
      this.resetPrice();
      this.polyName = ''
      this.polyEditor.close();
      this.polyEditor.setTarget();
      this.polyEditor.open();
      this.showSaveButton = true

      this.editorType = 1
    },

    /**
     * 取消编辑
     * */
    cancel() { //取消
      if(this.editorType==1) { //删除新建的划区
        this.currentTarget.setMap(null); //删除划区
        this.currentTarget.destroy(); //销毁内存
        this.pArr.splice(this.pIndex,1);
        this.pIndex--
        this.polyEditor.close(); //关闭编辑
        this.showEditor = false;
        this.showSaveButton = false;
        this.editorType = 0
      }else { //还原编辑之前的选区
        let id = this.currentTarget.getExtData().id;
        let index = this.currentTarget.getExtData().index;
        this.resetPolygon(id,index)
      }
    },
    /**
     * 打开链接 划区  绑定事件
     * */
    openLink() {
      console.log(this.pArr.length);
      if(this.pArr.length<=1) {
        this.$alert('当前只有一个划区，请添加后重试',{type: 'error'})
      }
      this.showEditor = false
      /*let group = new AMap.OverlayGroup([polyEditor])
      let arr = group._overlays[0]*/
      let id = this.currentTarget.getExtData().id;
      this.linkArr.push(id);
      for(let item of this.pArr) {
        item.on('click',this.selectLink)
      }
    },
    /**
     * 清除绑定的链接事件
     *  */
    clearLink() {
      for(let item of this.pArr) {
        item.off('click',this.selectLink)
      }
      this.linkArr = []
    },
    /**
     * 选择并划区并绑定
     * */
    selectLink(item) {
      item = item.target
      let extData = item.getExtData(); //当前点击的划区参数
      if(extData.id==this.linkArr[0]) {
        return this.$alert('不可选择当前选中的区域',{type: 'error'})
      }else {
        this.linkArr.push(extData.id)
        this.showPrice = true
      }
    },
    /**
     * 编辑当前选中的划区
     * */
    openEditor() {
      this.showEditor = false
      this.polyEditor.setTarget(this.currentTarget);//设置添加的划区为选中的对象
      this.polyEditor.open();//打开该划区的编辑
      this.showSaveButton = true
    },

    /**
     * 关闭编辑窗口
     * */
    closeEditorWindow(type) {
      if(type==1) {
        this.showEditor = false;
      }else if(type==2) {
        this.showPrice = false
      }
    },
    /**
     * 行政区查询
     * */
    drawBounds() {
      // 搜索所有省/直辖市信息
      if(this.keyword==='') {
        return this.$alert('请输入搜索内容',{type: 'error'})
      }
      this.districtSearch.search(this.keyword, (status, result)=> {
        if(status==='no_data') {
          return this.$message.error('未搜索到结果')
        }
        if(status==='error') {
          return this.$message.error('搜索失败，请重试')
        }
        if(result.info === 'OK') {
          this.$message.success('添加成功')
          this.addSearchPolygon(result)
        }
      })
    },
    /**
     * 添加搜索行政区的划区
     * */
    addSearchPolygon(data) {
      let that = this;
      let polygons = []
      for(let path of data.districtList[0].boundaries) {
        let polygon = new AMap.Polygon({
          path: path,
          strokeColor: that.strokeColor,//线条颜色
          fillColor: that.fillColor,//填充颜色
        })
        polygon.setExtData({index: that.pIndex})
        that.pArr.push(polygon);
        that.polyEditor.addAdsorbPolygons(polygon); //添加划区
        // let center = that.getPointsCenter(polygon.getOptions().path); //获取中心点位置
        // that.createText(center);
        that.pIndex++ //测试用
        //绑定双击事件
        polygon.on('dblclick', (dbevent) => {
          that.currentNamePostion = [
            dbevent.lnglat.lng,
            dbevent.lnglat.lat,
          ]
          that.showEditor = true
          that.currentTarget = polygon;
          let name = that.currentTarget.getExtData().name || null;
          if(name) that.polyName = name
        })
        //绑定鼠标移入事件
        polygon.on('mouseover', () => {
          polygon.setOptions({
            strokeColor: that.strokeActiveColor,//线条颜色
            fillColor: that.fillActiveColor,//填充颜色
          })
        })
        //绑定鼠标移出事件
        polygon.on('mouseout', () => {
          let data = polygon.getExtData()
          if(data.isGuanlian) {
            polygon.setOptions({
              strokeColor: that.strokeColor,//线条颜色
              fillColor: that.fillColor,//填充颜色
            })
          }else {
            polygon.setOptions({
              strokeColor: that.defaultStrokeColor,//线条颜色
              fillColor: that.defaultFillColor,//填充颜色
            })
          }
        })
        polygons.push(polygon)
      }
      //添加区域
      this.map.add(polygons)
    },
    /**
     * 选择行政区查询等级
     * */
    changeLevel(e) {
      this.districtSearch.setLevel(e);
    },
    /**
     * 编辑时添加点标记
     * */
    addMarker(index,data) {
      if(this.tArr[index]) {
        console.log('修改');
        // 自定义点标记内容
        let marker = this.tArr[index]
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = this.polyName;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        marker.setPosition(JSON.parse(data.name_rail)); //更新点标记位置
        this.$forceUpdate()
      }else {
        let marker = new AMap.Marker({
          position: JSON.parse(data.name_rail)
        });
        marker.setMap(this.map); //向地图添加标记
        // 自定义点标记内容
        var markerContent = document.createElement("div");
        // 点标记中的图标
        var markerImg = document.createElement("img");
        markerImg.className = "markerlnglat"; //标记类名
        markerImg.src = "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png"; //标记图标
        //设置标记属性 宽高
        markerImg.setAttribute('width', '25px');
        markerImg.setAttribute('height', '34px');
        markerContent.appendChild(markerImg);
        // 点标记中的文本
        var markerSpan = document.createElement("span");
        markerSpan.className = 'marker';
        markerSpan.innerHTML = this.polyName;
        markerContent.appendChild(markerSpan);
        marker.setContent(markerContent); //更新点标记内容
        this.tArr[index] = marker
        this.$forceUpdate()
      }
    },



    //表格操作  选择行
    selectRow(val) {
      this.currentRow = val;
    },
    /**
     * 表格点击查看按钮
     * */
    check(index,item) {
      let id = item.id
      console.log(this.pArr);
      let dbevent = this.pArr.find(item => {
        let dbId = item._opts.extData.id
        return id==dbId
      })
      if(dbevent) {
        this.currentNamePostion = JSON.parse(item.name_rail)
        this.showEditor = true
        this.currentTarget = dbevent;
        let name = this.currentTarget.getExtData().name || null;
        if(name) this.polyName = name
      }else {
        this.$alert('查看失败，请刷新重试',{type: 'error'})
      }

    },
    /**
     * 设置编辑区域价格
     * */
    setPrice(index,item) {
      this.updatePriceId = item.id
      this.polyPrice = item.price
      this.linkArr[0] = item.zid
      this.linkArr[1] = item.fid
      this.showPrice = true
    },
    /**
     * 取消区域价格显示
     * */
    resetPrice() {
      //恢复默认 鼠标移入 移除时的颜色
      this.showCheckPrice = false
      this.strokeColor = this.defaultStrokeColor
      this.fillColor = this.defaultFillColor
      this.strokeActiveColor = this.defaultStrokeActiveColor
      this.fillActiveColor = this.defaultFillActiveColor
      //恢复已有选区 的默认颜色
      for(let item of this.pArr) {
        item.setOptions({
          strokeColor: this.strokeColor,//线条颜色
          fillColor: this.fillColor,//填充颜色
        })
      }
    },
    /**
     * 取消订单
     * */
    cancelOrder(item) {
      this.$confirm('确认要取消该订单吗？',{type: 'error'},'取消中')
          .then(con => {
            this.$axios("POST",'/index/camcelOrder',{id: item.id})
                .then(res => {
                  if(res.status==1) {
                    this.$message.success('取消成功')
                    setTimeout(() => {
                      this.searchOrder()
                    },1200)
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })
    },


    //接口请求
    /**
     * 获取区域列表
     * */
    getList() {
      this.$axios("POST","/index/getRail",{id: this.id})
          .then(res => {
            if(res.status==1) {
              this.tableData = res.data.filter(item => {
                return item.name!==''
              })
              console.log(this.tableData);
              this.addPolygon(res.data)
              this.$message.success('获取成功')
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    /**
     * 删除选中的划区
     * */
    deletePolygon() {
      this.$confirm('确认要删除该围栏吗？',{type: 'warning'})
          .then(con=>{
            this.$message.info('删除中')
            let data = {
              ids: this.currentTarget.getExtData().id
            }
            this.$axios("POST","/index/delRail",data)
                .then(res => {
                  if(res.status==1) {
                    this.$message.success('删除成功')
                    this.polyName = ''
                    this.showEditor = false;
                    let index = this.currentTarget.getExtData().index;
                    try{
                      this.tArr[index].setMap(null);//删除划区中的文本内容
                    }catch (e) {}
                    this.currentTarget.setMap(null); //删除划区
                    this.currentTarget.destroy(); //销毁内存
                    this.clearLink(); //清除绑定的链接事件

                    //删除表格中的数据
                    let tindex = this.tableData.findIndex(item => {
                      return item.id == data.ids
                    })
                    if(tindex>=0) this.tableData.splice(tindex,1)
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })

    },
    /**
     * 取消已有选区编辑
     * */
    resetPolygon(id,index) {
      this.$axios("POST","/index/getRails",{
        id
      }).then(res => {
        if(res.status==1) {
          // let index = this.currentTarget.getExtData().index;
          let path = JSON.parse(res.data.rail)
          this.currentTarget.setPath(path)
          this.polyEditor.close(); //关闭编辑
          this.showEditor = false;
          this.showSaveButton = false;
          this.editorType = 0
        }
      })
    },

    /**
     * 获取围栏关联信息
     * */
    getRailLinkInfo(id=false) {
      if(!id) {
        id = this.currentTarget.getExtData().id;
      }
      this.priceId = id
      this.$message.info('获取中')
      this.$axios("POST","/index/getRailLinkInfo",{id: id})
          .then(res => {
            if(res.status==1) {
              this.showEditor = false
              this.priceList = res.data
              this.showCheckPrice = true
              this.setMapShowPrice() //设置价格关联的区域样式
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
  },
  mounted() {
    this.initData(this.updateId)
    this.id = this.$route.query.id || '1'
    this.init(); //初始化地图
    this.getPlace(); //获取出发地
    // this.getRailLogInfo() //获取核单列表
    // this.getDriceList() //获取司机列表
    this.getDriveList() //获取司机列表
  }
}
</script>

<style scoped>
#map3{
  width: 50%;
  height: 60vh;
  border: 1px solid #000;
}

.list{
  overflow-x: auto;
  width: 100%;
}
.list_item{
  min-height: 40px;
  background-color: rgb(232,246,252);
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 40px;
}
.list_item:nth-child(2n) {
  background-color: #ffffff;
}

.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}

.my-autocomplete li  .name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.my-autocomplete li  .addr {
  font-size: 12px;
  color: #b4b4b4;
}

.my-autocomplete li  .highlighted .addr {
  color: #ddd;
}
</style>