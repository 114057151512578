import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './assets/css/oneoneBootstrap.css'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/animation.css' //color ui 动画库
import request from "@/assets/js/axios";
import get from "@/assets/js/get";
import all from "@/assets/js/all";

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.prototype.$axios = request;
Vue.prototype.$get = get;
Vue.prototype.$all = all;
Vue.use(Viewer)
Vue.use(ElementUI);
// 修改elementui message时间
import {Message} from 'element-ui'
Vue.prototype.$message = function(msg){
  return Message({
    message: msg,
    duration: 1200
  })
}
Vue.prototype.$message2 = function(msg){
  return Message({
    message: msg,
    duration: 1200,
    offset: 80,
    type: 'success'
  })
}
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1200
  })
}
Vue.prototype.$message.info = function (msg) {
  return Message.info({
    message: msg,
    duration: 1200
  })
}
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1200
  })
}
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1200
  })
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
